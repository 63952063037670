import styled from 'styled-components'

interface BtnContatoProps {
  isActive: boolean;
}
export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  position: relative;

  .info {
    position: absolute;
    top: 30px;
    right: 30px;

    width: 22.5px;
    height: 22.5px;
    color: ${({ theme }) => theme.colors.mainColor};
    cursor: pointer;
  }

  strong {
    text-align: center;
    font-size: 18px;
    color: #0071BA;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > span {
    display: flex;
    width: 95%;
  }

  > small {
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 11px;
    font-style: italic;
    text-align: center;
  }

  ul {
    margin: 10px 25px 15px;
    font-size: 13px;
  }

  li {
    margin-bottom: 5px;
    list-style: circle;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > small {
      width: 90%;
    }
  }
`

export const RadioButton = styled.div`
  position: relative;

  width: 100%;
  margin-top: 20px;

  > div {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`

export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #4BA134, #31841A)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #4BA134'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`


interface BtnModalProps {
  isActive: boolean;
}
export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #537FE6, #4EB0FC)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #537FE6'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`

