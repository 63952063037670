import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FiMail, FiUser, FiSmartphone } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { BiFemale, BiCake, BiIdCard, BiBuilding } from 'react-icons/bi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import moment from 'moment'
import { Base64 } from 'js-base64'
import { Container, Content, BtnVoltar, BtnModal } from './styles'
import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
import getValidationErrors from '../../utils/getValidationErrors'

import {
  UserData,
  // FinancialIndicationData,
  LegalIndicationData,
  ConfigData,
  ErroProps,
  UserDetails,
} from '../../utils/interfaces'
import validaCPF from '../../utils/validaCPF'
import calculaIdade from '../../utils/calculaIdade'
import api from '../../services/api'
import ModalBox from '../../components/Modal'
import InputSelect from '../../components/InputSelect'
import { validaCNPJ } from '../../utils/validaCNPJ'
import { useLeadLovers } from '../../hooks/mectrics'

const PersonalInfos: React.FC = () => {
  const leadLovers = useLeadLovers()

  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  // const [
  //   financialIndicationData,
  //   setFinancialIndicationData,
  // ] = usePersistedState<FinancialIndicationData>(
  //   'financialIndicationData',
  //   {} as FinancialIndicationData,
  // )
  const [legalIndicationData, setLegalIndicationData] = usePersistedState<
    LegalIndicationData
  >('legalIndicationData', {} as LegalIndicationData)
  const [menorIdade, setMenorIdade] = usePersistedState('menorIdade', false)
  const [respLegal, setRespLegal] = usePersistedState('respLegal', false)
  const [, setRespFinanceiro] = usePersistedState('respFinanceiro', false)
  const [, setRespLegalEqualFin] = usePersistedState('respLegalEqualFin', 'N')
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [sexo, setSexo] = useState({
    label: userData.dcrSexo ? userData.dcrSexo : '',
    value: userData.sexo ? userData.sexo : '',
  })

  const [isMenorModalOpen, setIsMenorModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Birthday: userData.birthdate,
      Gender: userData.sexo,
      Notes: 'Última página: Informações pessoais',
    }

    leadLovers.put(leadData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        const aa = data.birthdate
        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]
        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .required('Celular obrigatório'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          rg: Yup.string()
            .when('filiacao', {
              is: 'F',
              then: Yup.string().required('RG é obrigatório'),
            })
            .when('filiacao', {
              is: 'V',
              then: Yup.string().required('RG é obrigatório'),
            }),
          cnpj: Yup.string().when('filiacao', {
            is: 'PJ',
            then: Yup.string()
              .required('CNPJ é obrigatório')
              .test(
                '',
                'CNPJ inválido',
                () =>
                  validaCNPJ(
                    data.cnpj
                      .replaceAll('.', '')
                      .replace('-', '')
                      .replace('/', ''),
                  ) || data.cnpj === '',
              ),
          }),
          birthdate: Yup.string()
            .required('Data de nascimento obrigatória')
            .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          sexo: Yup.string().required('É necessário informar o sexo.'),
          parental: Yup.string()
            .required('O nome da mãe é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          nomeResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('Nome do responsável legal é obrigatório')
              .matches(/\s/g, 'Digite o nome completo'),
          }),
          cpfResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavelLeg.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavelLeg === '',
              ),
          }),
          birthdateResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('Data de nascimento obrigatória')
              .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
              .test(
                '',
                'Data inválida.',
                () =>
                  moment(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ).isValid() || data.birthdateResponsavelLeg === '',
              )
              .test(
                '',
                'O responsável legal deve ter 18 anos ou mais.',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ) >= 18 || data.birthdateResponsavelLeg === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  calculaIdade(
                    data.birthdateResponsavelLeg.split('/').reverse().join('-'),
                  ) <= 115 || data.birthdateResponsavelLeg === '',
              ),
          }),
          telResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .max(15, 'Telefone inválido')
              .required('Celular obrigatório'),
          }),
          emailResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserData({
          ...userData,
          name: data.name,
          phone: data.phone,
          email: data.email,
          cpf: data.cpf,
          rg: data.rg ? data.rg : '',
          cnpj: data.cnpj ? data.cnpj : '',
          birthdate: dataForm,
          parental: data.parental,
          sexo: sexo.value,
          dcrSexo: sexo.label,
        })

        if (menorIdade) {
          await setLegalIndicationData({
            ...legalIndicationData,
            nomeResponsavelLeg: data.nomeResponsavelLeg,
            cpfResponsavelLeg: data.cpfResponsavelLeg,
            birthdateResponsavelLeg: data.birthdateResponsavelLeg
              .split('/')
              .reverse()
              .join('-'),
            telResponsavelLeg: data.telResponsavelLeg,
            emailResponsavelLeg: data.emailResponsavelLeg,
          })
        } else {
          await setLegalIndicationData({
            ...legalIndicationData,
            nomeResponsavelLeg: '',
            cpfResponsavelLeg: '',
            birthdateResponsavelLeg: '',
            telResponsavelLeg: '',
            emailResponsavelLeg: '',
          })
        }

        const parametros = Base64.encode(
          `{
            "versao": "${configData.tipo}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "nascimento": "${data.birthdate.split('/').reverse().join('-')}"
          }`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsValidaCpf.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            if (res.data.novoUsuario === 'N') {
              setIsModalOpen(true)
            } else {
              history.push('/address-infos')
            }
          })
          .catch(res => {
            setErroProps({
              title: 'Erro interno no servidor',
              description: res.message,
            })
            history.push('/erro')
          })
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [
      configData,
      history,
      legalIndicationData,
      menorIdade,
      setErroProps,
      setLegalIndicationData,
      setUserData,
      userData,
      sexo,
    ],
  )

  const handleDataNascimento = useCallback(
    e => {
      const idade = calculaIdade(e.split('/').reverse().join('-'))
      if (idade < 18) {
        setRespLegal(true)
        setRespFinanceiro(true)
        setMenorIdade(true)
        if (!legalIndicationData.cpfResponsavelLeg) setIsMenorModalOpen(true)
      } else {
        setMenorIdade(false)
        setRespLegal(false)
        setRespFinanceiro(false)
        setRespLegalEqualFin('N')
      }
    },
    [
      legalIndicationData,
      setMenorIdade,
      setRespFinanceiro,
      setRespLegal,
      setRespLegalEqualFin,
    ],
  )

  const handleChangeSexo = useCallback(data => {
    setSexo(data)
  }, [])

  useEffect(() => {
    if (userData.birthdate) handleDataNascimento(userData.birthdate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
            cpf: userData.cpf,
            filiacao: userDetails.filiacao,
            rg: userData.rg,
            cnpj: userData.cnpj,
            birthdate:
              userData.birthdate === undefined
                ? ''
                : userData.birthdate.split('-').reverse().join('/'),
            parental: userData.parental,
            nomeResponsavelLeg: legalIndicationData.nomeResponsavelLeg,
            cpfResponsavelLeg: legalIndicationData.cpfResponsavelLeg,
            birthdateResponsavelLeg:
              legalIndicationData.birthdateResponsavelLeg === undefined
                ? ''
                : legalIndicationData.birthdateResponsavelLeg
                    .split('-')
                    .reverse()
                    .join('/'),
            telResponsavelLeg: legalIndicationData.telResponsavelLeg,
            emailResponsavelLeg: legalIndicationData.emailResponsavelLeg,
          }}
        >
          <Content>
            <strong>
              Queremos saber mais sobre você, {userData.name.split(' ')[0]}:
            </strong>
            <InputHidden name="contribution" type="hidden" />
            <InputHidden
              name="responsavelLeg"
              type="hidden"
              value={menorIdade ? 'S' : 'N'}
            />
            <InputHidden name="filiacao" type="hidden" />

            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Seu celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="Seu e-mail pessoal"
            />
            <Input
              placeholder="Digite seu CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              maxLength={11}
            />
            {userDetails.filiacao === 'PJ' ? (
              <Input
                placeholder="Digite seu CNPJ"
                name="cnpj"
                icon={BiBuilding}
                type="tel"
                mask="cnpj"
                maxLength={14}
              />
            ) : (
              <>
                <Input
                  placeholder="Digite seu RG"
                  name="rg"
                  icon={BiIdCard}
                  type="tel"
                  maxLength={15}
                />
                <small className="info">
                  Inserir o número &quot;0&quot;, caso não tenha o documento.
                </small>
              </>
            )}
            <InputSelect
              name="sexo"
              value={sexo}
              options={[
                { label: 'Masculino', value: 'M' },
                { label: 'Feminino', value: 'F' },
                { label: 'Outro', value: 'O' },
              ]}
              onChange={e => handleChangeSexo(e)}
              placeholder="Sexo (conforme certidão de nascimento)"
            />
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              placeholder="Data de nascimento"
              maxLength={10}
              onBlur={e => handleDataNascimento(e.target.value)}
            />
            <Input
              icon={BiFemale}
              name="parental"
              placeholder="Nome Completo da Mãe"
            />

            <Collapse in={respLegal}>
              <strong>Informe os dados do responsável legal</strong>
              <Input
                placeholder="Nome do responsável legal"
                name="nomeResponsavelLeg"
                icon={FiUser}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    nomeResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="CPF do responsável legal"
                name="cpfResponsavelLeg"
                icon={MdSecurity}
                mask="cpf"
                maxLength={14}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    cpfResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                icon={BiCake}
                name="birthdateResponsavelLeg"
                mask="date"
                placeholder="Data de nascimento do responsável legal"
                maxLength={10}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    birthdateResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="Telefone do responsável legal"
                name="telResponsavelLeg"
                type="tel"
                mask="phone"
                icon={FiSmartphone}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    telResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="E-mail do responsável legal"
                name="emailResponsavelLeg"
                type="mail"
                icon={FiMail}
                onChange={e => {
                  setLegalIndicationData({
                    ...legalIndicationData,
                    emailResponsavelLeg: e.target.value,
                  })
                }}
              />
            </Collapse>
          </Content>
        </Form>

        <Button type="submit" color="blue" onClick={handleClick}>
          Avançar
        </Button>
        <BtnVoltar
          type="button"
          onClick={() => history.push('/goal-selection')}
        >
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <div className="confirmations">
            <p>
              Você confirma que o CPF {userData.cpf} pertence à {userData.name}?
            </p>
            <span>
              *Caso já exista um registro para este CPF e data de nascimento na
              base de dados, o cadastro será atualizado.
            </span>

            <div>
              <BtnModal
                isActive
                onClick={() => {
                  history.push('/address-infos')
                  setIsModalOpen(false)
                }}
              >
                Confirmar
              </BtnModal>

              <BtnModal isActive onClick={() => setIsModalOpen(false)}>
                Cancelar
              </BtnModal>
            </div>
          </div>
        </ModalBox>

        <ModalBox
          isOpen={isMenorModalOpen}
          onRequestClose={() => setIsMenorModalOpen(false)}
        >
          <p>Necessário incluir representante legal.</p>
          <BtnModal isActive onClick={() => setIsMenorModalOpen(false)}>
            Confirmar
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default PersonalInfos
