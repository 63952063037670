import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { BiHome, BiPlusMedical } from 'react-icons/bi'
import { FiShoppingBag, FiBook } from 'react-icons/fi'
import { RiHandCoinLine } from 'react-icons/ri'
import { IoMdAirplane } from 'react-icons/io'

import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content, GoalItem, GridItems } from './styles'
import { UserData } from '../../utils/interfaces'
import { useLeadLovers } from '../../hooks/mectrics'



const GoalSelection: React.FC = () => {

  const leadLovers = useLeadLovers()
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [goalsSelected, setGoalsSelected] = usePersistedState<string[]>(
    'goalsSelected',
    [],
  )

  const goalItems = [
    {
      name: 'Aposentar',
      id: 'AP',
      icon: <RiHandCoinLine />,
    },
    {
      name: 'Viajar',
      id: 'VJ',
      icon: <IoMdAirplane />,
    },
    {
      name: 'Educar os filhos',
      id: 'ED',
      icon: <FiBook />,
    },
    {
      name: 'Comprar imóveis',
      id: 'IM',
      icon: <BiHome />,
    },
    {
      name: 'Deixar bens para herdeiros',
      id: 'HE',
      icon: <BiPlusMedical />,
    },
    {
      name: 'Outros',
      id: 'OU',
      icon: <FiShoppingBag />,
    },
  ]

  const history = useHistory()

  const toggleSelected = useCallback(
    (index, v) => {
      if (goalsSelected.filter(id => id === v).length) {
        setGoalsSelected(() => goalsSelected.filter(id => id !== v))
      } else {
        setGoalsSelected([...goalsSelected, v])
      }
    },
    [goalsSelected, setGoalsSelected],
  )

  const handleContinue = useCallback(() => {
    history.push('/register')
  }, [history])

  useEffect(() => {
    console.log('passei')
    const leadData =  {
      Email: userData.email,
      Notes:'Última página: Objetivos',
    }

    leadLovers.put(leadData)

  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Quais são os seus principais objetivos ao investir em previdência
            privada?
          </strong>
          <p>Selecione uma ou mais opções:</p>

          <GridItems>
            {goalItems.map((value, index) => (
              <GoalItem
                key={value.name}
                isSelected={
                  !!(
                    goalsSelected.length &&
                    goalsSelected.filter(id => {
                      return id === value.id
                    }).length
                  )
                }
                onClick={() => toggleSelected(index, value.id)}
              >
                <div>
                  {value.icon}
                  <p>{value.name}</p>
                </div>
              </GoalItem>
            ))}
          </GridItems>

          <small>
            O Plano FamíliaPrev é uma opção inteligente e segura de você
            juntar dinheiro para realizar seus sonhos de longo prazo.
            <br />
            Por isso, o ideal é que pense nos seus planos para daqui, no mínimo,
            dez anos.
          </small>
        </Content>

        <Button
          type="button"
          color="blue"
          onClick={handleContinue}
          disabled={!goalsSelected.length}
        >
          Continuar
        </Button>
      </Container>
    </>
  )
}

export default GoalSelection
