/* eslint-disable radix */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FiClock } from 'react-icons/fi'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Content,
  BoxValue,
  BtnVoltar,
  Line,
  BtnModal,
} from './styles'
import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import wallet from '../../assets/wallet.svg'
import money from '../../assets/goal-retirement.svg'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
import getValidationErrors from '../../utils/getValidationErrors'
import { ValorMascarar } from '../../utils/masks'
import { useLeadLovers } from '../../hooks/mectrics'

import { UserData, UserDetails } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const Contribution: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const leadLovers = useLeadLovers()
  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Notes: 'Contribuição',
    }

    leadLovers.put(leadData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [contribValue, setContribValue] = useState(
    userDetails.contribuicaoBasica ? userDetails.contribuicaoBasica : 50,
  )

  const [aportValue, setAportValue] = useState(
    userDetails.aport ? userDetails.aport : 0,
  )

  const [dcrContribValue, setDcrContribValue] = useState(
    ValorMascarar(`${contribValue.toFixed(2)}`),
  )

  const [dcrAportValue, setDcrAportValue] = useState(
    ValorMascarar(`${aportValue.toFixed(2)}`),
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const changeFormContrib = useCallback(
    val => {
      const field = formRef.current?.getFieldRef('contribution')
      field.value = val
      setUserDetails(() => ({ ...userDetails, contribuicaoBasica: val }))
    },
    [setUserDetails, userDetails],
  )

  const changeFormAport = useCallback(
    val => {
      setUserDetails(() => ({ ...userDetails, aport: val }))
    },
    [setUserDetails, userDetails],
  )

  const mudarContrib = useCallback(valor => {
    const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
    setContribValue(parseFloat(v))
    setDcrContribValue(ValorMascarar(valor))
  }, [])

  const mudarAport = useCallback(valor => {
    const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
    setAportValue(parseFloat(v))
    setDcrAportValue(ValorMascarar(valor))
    // setUserDetails({ ...userDetails, aport: v })
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          years: Yup.number()
            .typeError('Digite o tempo para realizar a simulação')
            .min(1, 'O tempo mínimo para contribuição é de 1 ano')
            .max(50, 'O tempo máximo para contribuição é de 50 anos')
            .required('Digite o tempo para realizar a simulação'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserDetails({
          ...userDetails,
          contribuicaoBasica: contribValue * 1,
          aport: aportValue * 1,
          years: data.years,
        })

        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(
          getValidationErrors(err as Yup.ValidationError),
        )
      }
    },
    [aportValue, contribValue, history, setUserDetails, userDetails],
  )

  const validarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setContribValue(0)
        setDcrContribValue('0,00')
        return
      }

      if (Number(v) < 50) {
        setIsModalOpen(true)
        setContribValue(parseFloat('50.00'))
        setDcrContribValue('50,00')
        return
      }

      setContribValue(parseFloat(v))
      setDcrContribValue(ValorMascarar(valor))
    },
    [setContribValue],
  )

  const validarAport = useCallback(valor => {
    const v = valor.replaceAll('.', '').replace(',', '.').replace('R$ ', '')
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(v)) {
      setAportValue(0)
      setDcrAportValue('0,00')
      return
    }

    setAportValue(parseFloat(v))
    setDcrAportValue(ValorMascarar(valor))
  }, [])

  useEffect(() => {
    changeFormContrib(contribValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contribValue])

  useEffect(() => {
    changeFormAport(aportValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aportValue])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              email: userData.email,
              phone: userData.phone,
              birthdate: userData.birthdate,
              parental: userData.parental,
              years: userDetails.years,
              contribution: userDetails.contribuicaoBasica,
              aportInitial: userDetails.aport,
            }}
          >
            <InputHidden name="contribution" type="hidden" />
            <Input
              icon={FiClock}
              sizeBox="large"
              name="years"
              type="number"
              min={1}
              sufix="anos"
              placeholder="Por quantos anos deseja contribuir?"
            />
          </Form>

          {/* <Line /> */}

          <BoxValue>
            <p>Qual valor mensal?</p>
            <div>
              <img src={wallet} alt="wallet" />

              <div>
                <p>Quero investir:</p>
                <input
                  id="contribution"
                  value={`R$ ${ValorMascarar(dcrContribValue)}`}
                  onChange={e => mudarContrib(e.target.value)}
                  min="0"
                  onBlur={e => validarContrib(e.target.value)}
                />
                <small>Valor mensal recorrente</small>
              </div>
            </div>
            <small>
              Você poderá realizar contribuições a partir de{' '}
              <span>R$ 50,00</span>, e caso tenha interesse, o valor poderá ser
              alterado no mês de maio de cada ano.
            </small>
          </BoxValue>
          <Line />
          <BoxValue>
            <p>Deseja fazer um aporte inicial?</p>
            <div>
              <img src={money} alt="money" />
              <div>
                <p>Valor do aporte</p>
                <input
                  id="aportInitial"
                  value={`R$ ${ValorMascarar(dcrAportValue)}`}
                  onChange={e => mudarAport(e.target.value)}
                  min="0"
                  onBlur={e => validarAport(e.target.value)}
                />
                {/* <small>Valor único</small> */}
              </div>
            </div>
            {/* <small>
              Você poderá realizar contribuições a partir de{' '}
              <span>R$ 50,00</span>, e caso tenha interesse, o valor poderá ser
              alterado no mês de maio de cada ano.
            </small> */}
          </BoxValue>
        </Content>

        <Button type="submit" color="blue" onClick={handleClick}>
          Simular Agora
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <div>
            <p>O aporte mensal não pode ser menor que R$ 50,00.</p>
          </div>
          <div>
            <BtnModal isActive onClick={handleCloseModal}>
              Ok
            </BtnModal>
          </div>
        </ModalBox>
      </Container>
    </>
  )
}

export default Contribution
