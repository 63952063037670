import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import { Form } from '@unform/web'

import usePersistedState from '../../hooks/usePersistedState'

import Header from '../../components/Header'
import Button from '../../components/Button'
import Dots from './components/Dots'

import welcomeEnd from '../../assets/welcome-end.svg'
import welcomeWallet from '../../assets/welcome-wallet.svg'
import welcomeHandPork from '../../assets/welcome-hand-pork.svg'
import { Container, Carousel, CarouselBox, BtnVoltar } from './styles'
import Input from '../../components/Input'
import { UserData } from '../../utils/interfaces'
import InputInit from '../../components/InputInit'

interface formValue {
  key: string
  value: string
}
const BemVindo: React.FC = () => {
  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const history = useHistory()
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [validEmail, setValidEmail] = useState<boolean>(false)

  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )

  const [quantity] = useState(3)
  const [active, setActive] = useState(0)

  const handleSetProximo = useCallback(() => {
    const newActive = active + 1
    if (newActive < quantity) {
      setActive(newActive)
    }
  }, [active, quantity])

  const handleSetAnterior = useCallback(() => {
    const newActive = active - 1
    if (newActive >= 0) {
      setActive(newActive)
    }
  }, [active])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  useMemo(() => {
    if (emailRegex.test(emailValue)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }, [emailValue])

  useMemo(() => {
    setUserData({ ...userData, name: nameValue })
  }, [nameValue])

  useMemo(() => {
    setUserData({ ...userData, phone: phoneValue })
  }, [phoneValue])

  useMemo(() => {
    setUserData({ ...userData, email: emailValue })
  }, [emailValue])

  const handleStart = useCallback(async () => {
    if (acceptTerms) {
      history.push('/filiacao')
    } else {
      console.warn('it is necessary accept the terms to continue.')
    }
  }, [acceptTerms, history])

  useEffect(() => {
    localStorage.removeItem('@CibriusFamilia:acceptTerms')
    localStorage.removeItem('@CibriusFamilia:authorizeTitularPayment')
  }, [])

  const onChangeMail = useCallback(val => setEmailValue(val), [setEmailValue])

  const onChangeName = useCallback(val => setNameValue(val), [setNameValue])

  const onChangePhone = useCallback(val => setPhoneValue(val), [setPhoneValue])
  return (
    <>
      <Header />
      <Container>
        <Carousel>
          <CarouselBox isActive={active === 0}>
            <img src={welcomeWallet} alt="Carteira" />
            <strong>Quer ter uma aposentadoria de verdade?</strong>
            <p>
              Descubra aqui como você pode ficar financeiramente bem no futuro!
            </p>
            <small>
              Fique tranquilo. Todos os seus dados são privados e estão
              protegidos por nossa{' '}
              <a
                href="https://cibrius.com.br/lgpd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                política de privacidade
              </a>
              .
            </small>
          </CarouselBox>

          <CarouselBox isActive={active === 1}>
            <img src={welcomeHandPork} alt="Carteira" />
            <strong>
              Investir no Plano FamíliaPrev proporciona dedução de até 12% da
              base de cálculo do seu Imposto de Renda anual.
            </strong>
          </CarouselBox>

          <CarouselBox isActive={active === 2}>
            <img src={welcomeEnd} alt="Carteira" />
            <strong>Aqui tudo é simples e direto!</strong>
            <p>
              Surgiu algo inesperado? <br />
              No Plano FamíliaPrev você tem opção de fazer resgates parciais a
              partir do 3º ano.
            </p>
            <div>
              <small>
                Ao continuar navegando no site, você concorda com as condições
                de{' '}
                <a
                  href="https://cibrius.com.br/lgpd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacidade
                </a>{' '}
                do site Cibrius.
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={22}
                width={44}
                handleDiameter={18}
                offColor="#DEE3E1"
                offHandleColor="#2F80ED"
                onHandleColor="#fff"
                onColor="#2F80ED"
              />
            </div>
          </CarouselBox>
        </Carousel>
        {active === 0 && (
          <div>
            <span>Para continuar, por favor digite seu e-mail</span>
            <Form onSubmit={() => console.info('validating input')}>
              <InputInit
                type="text"
                name="email"
                placeholder="Digite seu email"
                handleChange={onChangeMail}
                value={emailValue}
              />
              {!validEmail && emailValue.length > 0 && (
                <span>O email deve ser válido</span>
              )}
            </Form>
          </div>
        )}

        {active === 1 && (
          <div>
            <span>Como devemos te chamar?</span>
            <Form onSubmit={() => console.info('validating input')}>
              <InputInit
                type="text"
                name="name"
                placeholder="Digite seu nome"
                handleChange={onChangeName}
                value={nameValue}
              />
            </Form>
          </div>
        )}

        {active === 2 && (
          <div>
            <span>Agora precisamos do seu telefone</span>
            <Form onSubmit={() => console.info('validating input')}>
              <InputInit
                type="text"
                name="phone"
                placeholder="Digite seu telefone"
                mask="phone"
                prefix="+55 | "
                handleChange={onChangePhone}
                value={phoneValue}
              />
            </Form>
          </div>
        )}

        <Dots quantity={quantity} active={active} />
        <Button
          color="blue"
          onClick={handleSetProximo}
          isVisible={active + 1 !== quantity}
          disabled={
            (!validEmail && active === 0) || (active === 1 && !nameValue)
          }
        >
          Próximo
        </Button>
        <Button
          color="blue"
          onClick={handleStart}
          disabled={!acceptTerms || phoneValue.length < 14 || !phoneValue}
          isVisible={active + 1 === quantity}
        >
          Começar
        </Button>

        {active > 0 && (
          <BtnVoltar type="button" onClick={handleSetAnterior}>
            &lt; Anterior
          </BtnVoltar>
        )}
      </Container>
    </>
  )
}

export default BemVindo
