import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  max-width: 600px;
  margin: 35px auto;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 500px;
  }
`

export const Content = styled.main`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px 20px;
  margin: 0 5px 18px;

  display:flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
    color: #0071BA;
  }

  img {
    height:185px;
    width: auto;
    margin: 30px 0px;
  }

  p {
    width: 80%;
    font-size: 14px;
    text-align: center;
    line-height:18px;
    padding-bottom: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: #333333;
      text-decoration: none;
      display: flex;
      align-items: center;
      position: relative;

      &:focus, &:hover{
        text-decoration: underline;
      }
    }
 }
`

export const Player = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;

  &::after {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 0;
    border-radius: 18px;
  }
`

export const SocialLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin-bottom: 1rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    height: 3rem;
    width: 3rem;
    background: linear-gradient(15deg, #193762, #2F80ED);
    box-shadow: 1px 1px 8px #00000050;
    transition: 0.2s;

    &:hover {
      box-shadow: none;
    }

    svg {
      color: #FFF;
      font-size: 1.25rem;
    }
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 30px auto 5px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const HandleNew = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 15px 0;
  width: 100%;

  h4 {
    font-size: 14px;
    color: #0071BA;
  }

  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      text-align: center;
      margin: 0 auto;
      font-weight: bold;
    }

    p {
      margin: 0;
      padding: 5px 0;
    }
  }
`
