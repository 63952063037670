/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Container, Content, ContentBenef, BenefBox, Line, MessageBox } from './styles'
import Header from '../../components/Header'
import Button from '../../components/Button'
import { FiCheck, FiTrash2 } from 'react-icons/fi'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'

import _cloneDeep from 'lodash/cloneDeep'
import { Participant } from '../../utils/interfaces'
import calculaIdade from '../../utils/calculaIdade'
import usePersistedState from '../../hooks/usePersistedState'
import { useHistory } from 'react-router-dom'
import { UserData } from '../../utils/interfaces'
import { useLeadLovers } from '../../hooks/mectrics'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const leadLovers = useLeadLovers()
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [partLength, setPartLength] = useState(participants.length)
  const [totalProporcao, setTotalProporcao] = useState(0)

  // const arr = participants.map(participant => participant.details.proporcao)

  // let soma = 0
  // for (let i = 0; i < arr.length; i += 1) {
  //   soma += arr[i]
  // }

  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  // const handleRemove = useCallback(
  //   id => {
  //     setParticipants(() =>
  //       participants.filter((participant, idx) => idx !== id),
  //     )
  //     setPartLength(participants.length)
  //   },
  //   [participants, setParticipants],
  // )

  // useEffect(() => {
  //   setPartLength(participants.length)
  //   setTotalProporcao(soma)
  // }, [setParticipants, participants, handleRemove, soma])

  async function handleConfigProportions() {
    if (partLength > 0) {
      let rateio = (100 / partLength).toFixed(2)

      for (let index = 0; index < partLength; index += 1) {
        dependentsListCopy[index].details.proporcao = parseFloat(rateio)
      }

      setParticipants(dependentsListCopy)
      setTotalProporcao(100)
    }
  }

  async function handleRemove(id: number) {
    const newParticipantsList = participants.filter((participant, index) => index !== id)
    setParticipants(newParticipantsList)
    setPartLength(newParticipantsList.length)
  }

  // useEffect(() => {
  //   handleConfigProportions()
  // }, [partLength])

  useEffect(() => {
    const leadData =  {
      Email: userData.email,
      Notes:'Última página: Lista de participantes',
    }
    leadLovers.put(leadData)
  }, [])

  useEffect(() => {
    calculateProportion()
  }, [partLength])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  const handleSubmit = useCallback(async () => {
    history.push('/ppe-fatca')
  }, [history])

  const handleChangeProportion = useCallback(
    async (value: string, dependentIndex: number) => {
      if (!value)
        dependentsListCopy[dependentIndex].details.proporcao = 0
      if (value && Number(value) <= 100)
        dependentsListCopy[dependentIndex].details.proporcao = parseFloat(value)
      if (value && Number(value) > 100)
        dependentsListCopy[dependentIndex].details.proporcao = 100
      setParticipants(dependentsListCopy)
      calculateProportion()
    },
    [dependentsListCopy]
  )

  const calculateProportion = useCallback(() => {
    let soma = 0
    for (let index = 0; index < partLength; index++) {
      soma += dependentsListCopy[index].details.proporcao
    }
    setTotalProporcao(soma)
  }, [dependentsListCopy])


  const teste = useCallback(
    async arrAux => {
      await setParticipants([...arrAux])
      setTotalProporcao(100)
    },
    [setParticipants],
  )

  const handleProporcionalizar = useCallback(async () => {
    const value = (100 / partLength).toFixed(2)

    const contador = partLength

    for (let i = 0; i < contador; i += 1) {
      dependentsListCopy[i].details.proporcao = parseFloat(value)
      setParticipants(dependentsListCopy)
    }
    teste(dependentsListCopy)
  }, [dependentsListCopy, partLength, teste])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <BenefBox>
              {participants.length > 0 ? (
                <>
                  <h3>Beneficiários</h3>
                  <p>Os beneficiários são de livre designação e podem ser alterados pelo participante a qualquer tempo.</p>
                </>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}

              {participants.map((participant, idx) => (
                <ContentBenef key={idx}>
                  <Scope path={`parts[${idx}]`}>
                    <FiTrash2 onClick={() => handleRemove(idx)} title='Excluir' />
                    <div>
                      <div>
                        <strong>Nome: </strong>
                        <label>{participant.data.name}</label>
                      </div>
                      <div>
                        <strong>Parentesco: </strong>
                        <label>{participant.details.dcrGrauParentesco}</label>
                      </div>
                      <div>
                        <strong>Idade: </strong>
                        <label>
                          {`${calculaIdade(participant.data.birthdate)} anos`}
                        </label>
                      </div>
                      <div>
                        <strong>Percentual de rateio: </strong>
                        <input
                          type='number'
                          min={1}
                          max={100}
                          value={participant.details.proporcao}
                          onChange={(e) => handleChangeProportion(e.target.value, idx)}
                        />%
                      </div>
                    </div>
                  </Scope>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          </Form>
        </Content>

        {partLength === 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="large"
            onClick={() => history.push('/beneficiary')}
          >
            Adicionar beneficiário
          </Button>
        ) : (
          <></>
        )}

        {totalProporcao !== 100 && partLength > 0 ? (
          <>
            <MessageBox>
              <p>
                Você ainda não distribuiu 100% da proporção entre seus
                beneficiários. Adicione mais beneficiários ou{' '}
                <span onClick={() => { handleConfigProportions() }}>
                  clique aqui
                </span>{' '}
                para distribuir igualmente entre todos os informados.
              </p>
            </MessageBox>
            <Button
              type="button"
              fontSize="normal"
              color="white"
              width="large"
              onClick={() => history.push('/beneficiary')}
            >
              Adicionar mais um beneficiário
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="green"
              width="large"
              onClick={handleClickSubmit}
            >
              {partLength === 0 ? (
                <span>Adicionar depois</span>
              ) : (
                <>
                  <FiCheck size={45} />
                  <span>Pronto! Avançar</span>
                </>
              )}
            </Button>
          </>
        )}

        {totalProporcao === 100 ? (
          <Button
            type="button"
            fontSize="small"
            color="white"
            width="small"
            onClick={() => history.push('/beneficiary')}
          >
            Adicionar mais um beneficiário
          </Button>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export default ParticipantsList
