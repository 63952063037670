import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import usePersistedState from '../../hooks/usePersistedState'
import {
  Container,
  Content,
  GoalItem,
  BtnVoltar,
  TaxationBox,
  Line,
} from './styles'
import Button from '../../components/Button'
import Header from '../../components/Header'
import { UserDetails, UserData } from '../../utils/interfaces'
import { useLeadLovers } from '../../hooks/mectrics'

const Filiacao: React.FC = () => {
  const history = useHistory()
  const leadLovers = useLeadLovers()
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [confirmInformation, setConfirmInformation] = usePersistedState(
    'confirmInformation',
    false,
  )

  const [selected, setSelected] = useState(
    userDetails.filiacao ? userDetails.filiacao : '',
  )
  const [descricFiliacao, setDescricFiliacao] = useState(
    getDescription(selected),
  )

  function getDescription(tipo: string) {
    if (tipo === 'F') {
      return `Acessível aos participantes ou assistidos dos planos do Cibrius já  existentes ou seus respectivos familiares.`
    }
    if (tipo === 'V') {
      return `Acessível às pessoas físicas seguradas do Regime Geral de Previdência Social (INSS), autônomos e prestadores de serviços.`
    }
    if (tipo === 'PJ') {
      return `Às pessoas jurídicas constituídas em território nacional e com registro
        ativo será permitida a vinculação na condição de Associado Especial
        Previdenciário - Pessoa Jurídica da ABRAPP, mediante indicação de uma ou mais
        Associadas, com a finalidade exclusiva de acesso
        às pessoas físicas vinculadas ao Associado Especial Previdenciário - Pessoa
        Jurídica da ABRAPP a plano de benefícios de caráter previdenciário, administrado
        e executado pela Entidade Associada que efetuar a indicação.`
    }

    return ''
  }

  const toggleConfirmInformation = useCallback(() => {
    setConfirmInformation(!confirmInformation)
  }, [confirmInformation, setConfirmInformation])

  function handleSelection(valor: string) {
    setSelected(valor)
    setDescricFiliacao(getDescription(valor))
  }

  async function handleContinue() {
    await setUserDetails({
      ...userDetails,
      filiacao: selected,
    })

    history.push('/contribution')
  }

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Name: userData.name,
      Phone: userData.phone,
      Notes: 'Última página: Filiação',
      tags: ['Adesão Familia prev'],
    }

    leadLovers.put(leadData)
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="app_title">
            <strong>Escolha o modelo de filiação:</strong>
            {/* <RiQuestionLine onClick={() => setIsModalOpen(true)} /> */}
          </div>
          <p>
            Você deve selecionar uma das possibilidades: ser parente de até 4°
            grau de alguma pessoa que tenha vínculo com as patrocinadoras, ser
            pessoa física e contribuir para o INSS ou ser pessoa jurídica.
          </p>
          <TaxationBox>
            <GoalItem
              key="1"
              isSelected={selected === 'F'}
              onClick={() => handleSelection('F')}
            >
              <span>Pessoa Física - Família</span>
            </GoalItem>
            <GoalItem
              key="2"
              isSelected={selected === 'V'}
              onClick={() => handleSelection('V')}
            >
              <span>Pessoa Física - Vinculado</span>
            </GoalItem>
          </TaxationBox>

          <Line />
          <footer>
            <p>{descricFiliacao}</p>

            <div className="confirm-info">
              <span>Você confirma a veracidade das informações prestadas?</span>
              <Switch
                onChange={toggleConfirmInformation}
                checked={confirmInformation}
                checkedIcon={false}
                uncheckedIcon={false}
                height={22}
                width={44}
                handleDiameter={18}
                offColor="#DEE3E1"
                offHandleColor="#2F80ED"
                onHandleColor="#fff"
                onColor="#2F80ED"
              />
            </div>
          </footer>
        </Content>

        <Button
          type="button"
          color="blue"
          onClick={handleContinue}
          disabled={!selected || !confirmInformation}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default Filiacao
