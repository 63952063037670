import styled from "styled-components"

export const Container = styled.div`
  background-color: ${props => props.theme.colors.mainColor};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(61, 69, 67, 0.08);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: fixed;
  color: #F4F6F5;
  font-weight: bold;

  right: 10px;
  bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: all .2s ease-in-out;

  div.hide {
    width: 0px;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    font-size: 12px;
    margin-left: 0;
  }

  svg {
    font-size: 30px;
  }

  &:hover {
    width: 150px;
    border-radius: 20px;

    div.hide {
      width: 100px;
      opacity: 1;
      margin-left: 5px;
    }
  }
`
