import React, { useEffect } from 'react'
import { Switch, useLocation, useHistory } from 'react-router-dom'

import Route from './Route'

import BemVindo from '../pages/BemVindo'
import Contribution from '../pages/Contribution'
import Simulation from '../pages/Simulation'
// import TypePerson from '../pages/TypePerson'
import Register from '../pages/Register'
import ConfirmSMS from '../pages/ConfirmSMS'
import GoalSelection from '../pages/GoalSelection'
import PersonalInfos from '../pages/PersonalInfos'
import AddressInfos from '../pages/AddressInfos'
import Beneficiary from '../pages/Beneficiary'
import ParticipantsList from '../pages/ParticipantsList'
import PpeFatca from '../pages/PpeFatca'
import ConfirmOwnership from '../pages/ConfirmOwnership'
import Conclusion from '../pages/Conclusion'
import End from '../pages/End'
import Erro from '../pages/Erro'
import NotFound from '../pages/NotFound'

import usePersistedState from '../hooks/usePersistedState'
import clientConfig from '../services/clientConfig'
import { ConfigData } from '../utils/interfaces'
import Filiacao from '../pages/Filiacao'
import Resume from '../pages/Resume'

const Routes: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()
  const location = useLocation()
  const history = useHistory()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  useEffect(() => {
    if (
      location.pathname !== '/' &&
      location.pathname !== '/end' &&
      !acceptTerms
    ) {
      history.push('/')
      return
    }

    setConfigData({
      ...configData,
      codCliente,
      tipo,
      plano,
    })
  }, [])

  return (
    <Switch>
      <Route path="/" exact component={BemVindo} />
      <Route path="/filiacao" component={Filiacao} />
      <Route path="/contribution" exact component={Contribution} />
      <Route path="/simulation" component={Simulation} />
      <Route path="/goal-selection" component={GoalSelection} />
      <Route path="/register" exact component={Register} />
      <Route path="/register/confirm-sms" component={ConfirmSMS} />
      <Route path="/personal-infos" component={PersonalInfos} />
      <Route path="/address-infos" component={AddressInfos} />
      <Route path="/beneficiary" component={Beneficiary} />
      <Route path="/participants-list" component={ParticipantsList} />
      <Route path="/ppe-fatca" component={PpeFatca} />
      <Route path="/confirm-ownership" component={ConfirmOwnership} />
      <Route path="/conclusion" component={Conclusion} />
      <Route path="/resume" component={Resume} />
      <Route path="/end" component={End} />
      <Route path="/erro" component={Erro} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
