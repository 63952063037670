import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content, HandleNew, Line, Player, SocialLinks } from './styles'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'

const End: React.FC = () => {

  const history = useHistory()

  const handleNewParticipant = useCallback(() => {
    history.push('/')

    localStorage.removeItem('@CibriusFamilia:acceptTerms')
    localStorage.removeItem('@CibriusFamilia:authorizeTitularPayment')
    localStorage.removeItem('@CibriusFamilia:goalsSelected')
    localStorage.removeItem('@CibriusFamilia:PercentualValuePercent')
    localStorage.removeItem('@CibriusFamilia:receiveTypeSelected')
    localStorage.removeItem('@CibriusFamilia:TimeValueYears')
    localStorage.removeItem('@CibriusFamilia:totalRentability')
    localStorage.removeItem('@CibriusFamilia:userDetails')
    localStorage.removeItem('@CibriusFamilia:confirmInformation')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de adesão ao Plano FamíliaPrev foi salvo
            com sucesso!
          </strong>
          <Player>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/hUqfFta6v0E?si=_JJc4tG_cTqde8PV&amp;start=6" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </Player>
          <p>
            Agora nossos especialistas irão conferir todos os detalhes para a
            conclusão da sua adesão.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a
              href="https://cibrius.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              cibrius.com.br
            </a>
          </div>

          <Line />

          <HandleNew>
            <h4>
              Que tal realizar a adesão de mais
              algum familiar?
            </h4>
            <Button
              type="button"
              color="blue"
              width="large"
              onClick={handleNewParticipant}
            >
              Sim, eu quero!
            </Button>
          </HandleNew>
        </Content>
        <SocialLinks>
          <a href="https://goo.gl/maps/RQkQ7JxE8Vt13sm89" target="_blank" rel="noopener noreferrer" title='Onde estamos localizados?'><MdLocationOn /></a>
          <a href="https://www.facebook.com/CibriusConab/" target="_blank" rel="noopener noreferrer" title='Visite nossa página no Facebook'><FaFacebook /></a>
          <a href="https://www.instagram.com/cibriusprevidencia/" target="_blank" rel="noopener noreferrer" title='Nos siga no Instagram'><FaInstagram /></a>
          <a href="https://wa.me/5561992251275" target="_blank" rel="noopener noreferrer" title='Nosso Whatsapp'><FaWhatsapp /></a>
          <a href="https://www.youtube.com/c/CibriusPrevid%C3%AAncia" target="_blank" rel="noopener noreferrer" title='Conheça nosso canal no Youtube'><FaYoutube /></a>
        </SocialLinks>
      </Container>
    </>
  )
}

export default End
