import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import Collapse from '@material-ui/core/Collapse'
import { FiAlertCircle } from 'react-icons/fi'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'
import InputSMSCode from '../../components/InputSMSCode'
import Button from '../../components/Button'
import Header from '../../components/Header'
import {
  Container,
  Content,
  Timer,
  LinkBack,
  AlertContent,
  BtnModal,
} from './styles'
import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'
import { useLeadLovers } from '../../hooks/mectrics'

const ConfirmSMS: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [reSend, setReSend] = useState(false)
  const [open, setOpen] = useState(false)
  const [sizeCode] = useState(4)
  const [timer, setTimer] = useState(120)
  const [counter, setCounter] = useState('02:00')
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const contato = configData.tipoContato === 'S' ? 'SMS' : 'e-mail'
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const leadLovers = useLeadLovers()

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      if (data.codeSMS.length === sizeCode) {
        const parametros = Base64.encode(
          `{"token": "${configData.token}",
          "codigoValidador": "${data.codeSMS}",
          "cliente": "${configData.codCliente}"}`,
        )

        const parametrosFinal = Base64.encode(parametros)
        await api
          .post(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(() => history.push('/personal-infos'))
          .catch(() => setOpen(true))
      }
    },
    [configData, history, sizeCode],
  )

  function updateCounter(duration: number) {
    let time = duration
    let minutes
    let seconds

    minutes = parseInt(String(duration / 60), 10)
    seconds = parseInt(String(duration % 60), 10)
    minutes = minutes < 10 ? `0${minutes}` : minutes
    seconds = seconds < 10 ? `0${seconds}` : seconds
    setCounter(`${minutes}:${seconds}`)

    if (--time < 0) {
      time = duration
    }
  }

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Name: userData.name,
      Phone: userData.phone,
      Notes: 'Última página: confirmação de código',
    }

    leadLovers.put(leadData)
  }, [])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
        updateCounter(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        updateCounter(timer - 1)
        setReSend(true)
      }
    }

    const interval = setInterval(countTimer, 1000)

    return () => clearInterval(interval)
  }, [timer])

  const handleReSend = useCallback(async () => {
    const parametrosGet = Base64.encode(
      `{"versao":"${configData.tipo}",
        "plano": "${configData.plano}",
        "cliente":"${configData.codCliente}",
        "cpf":"${
          userData.cpf === undefined
            ? ''
            : userData.cpf.replaceAll('.', '').replace('-', '')
        }",
        "email":"${userData.email === undefined ? '' : userData.email}",
        "telefone":"${
          userData.phone === undefined
            ? ''
            : `55${userData.phone
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "admissao":"",
        "envio":"${configData.tipoContato}"}`,
    )

    const parametrosFinalGet = Base64.encode(parametrosGet)

    await api
      .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinalGet}`)
      .then(res => setConfigData({ ...configData, token: res.data.token }))
      .catch(res => {
        if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else {
          setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })

    history.push('/register/confirm-sms')

    setReSend(false)
    setTimer(120)
    setCounter('02:00')
  }, [configData, history, setConfigData, setErroProps, userData])

  return (
    <>
      <Header />

      <Container>
        <Content>
          <span>
            {userData?.name.split(' ')[0]}, digite aqui o código de verificação
            que acabamos de te enviar via {contato}
          </span>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputSMSCode name="codeSMS" size={sizeCode} formRef={formRef} />
          </Form>

          <Collapse in={open}>
            <AlertContent>
              <div>
                <FiAlertCircle />
                <p>Código incorreto!</p>
              </div>
              <button type="button" onClick={() => window.location.reload()}>
                Limpar
              </button>
            </AlertContent>
          </Collapse>

          <Timer>{counter}</Timer>
          <Button
            onClick={handleReSend}
            type="button"
            color="blue"
            disabled={!reSend}
          >
            Reenviar
          </Button>
          <p>
            Clique em <strong>reenviar</strong> para receber um novo código
          </p>
          <LinkBack to="/register">Quero trocar a forma de contato</LinkBack>
          <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
            {txtModal}
            <BtnModal isActive onClick={() => handleCloseModal()}>
              Ok
            </BtnModal>
          </ModalBox>
        </Content>
      </Container>
    </>
  )
}

export default ConfirmSMS
