import styled, { css } from 'styled-components';

interface GoalItemProps {
  isSelected: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #0071BA;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  small {
    font-size: 11px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const GridItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 8px;
  justify-items: center;

  padding: 5px 20px;
  margin: 15px auto;
  width: 100%;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr) ;
    grid-template-rows: repeat(3, 1fr);
  }
`

export const GoalItem = styled.button<GoalItemProps>`
  width: 140px;
  height: 80px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid ${props => props.isSelected ? '#0071BA' : '#DEDEDE'};
  color: ${props => props.isSelected ? '#0071BA' : '#333'};

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;


    height: 100%;
    width: 100%;

    svg {
      font-size: 28px;
      align-self: center;
      margin-bottom: 5px;
    }

    p {
      height: 45%;
      width: 100%;
      font-size: 12px;
      color: #333;
    }
  }
`


