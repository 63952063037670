import Axios from 'axios'
import { API_TOKEN } from '../const/ApiTokens'

export function useLeadLovers() {
  const BASE_URL = 'https://llapi.leadlovers.com/'
  // const BASE_URL = ''
  const machineCode = 733072
  const emailSequenceCode = 1724520
  const sequenceLevelCode = {
    Items: [
      {
        ModelCode: 7832856,
        Sequence: 1,
        Subject: 'Assunto do primeiro e-mail',
      },
    ],
  }
  function post(newData: {}) {
    const data = {...newData, machineCode: machineCode, emailSequenceCode: emailSequenceCode, sequenceLevelCode: sequenceLevelCode}
    Axios.post(`${BASE_URL}webapi/Lead?token=${API_TOKEN.leadLovers}`, data)
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }

  function put(newData: {}) {
    Axios.put(`${BASE_URL}webapi/Lead?token=${API_TOKEN.leadLovers}`, newData)
      .then(response => console.log(response))
      .catch(error => console.log(error))
  }

  return { post, put }
}
