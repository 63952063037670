import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #F7F8FE;
    background-image: linear-gradient(180deg, #3F7CCC 0%, #F7F8FE 40%);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.colors.text};
  }

  body, input, button, textarea {
    font-family: 'Verdana', 'Roboto', sans-serif;
    font-size: 14px;
  }

  button {
    cursor: pointer;
    color: ${props => props.theme.colors.text};
  }

  .react-switch-bg {
    border: solid 1px #2F80ED;
  }

  .react-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: rgba(0, 0, 0, .55);
  }

  .react-modal-content {
    position: relative;
    padding: 35px 24px;
    width: 500px;
    max-height: 80vh;
    overflow: auto;

    background: #F4F6F5;
    border-radius: 4px;

    &::-webkit-scrollbar {
      border-radius: 3px;
      width: 5px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: ${transparentize(0.7, '#C3C3C3')};
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #C3C3C3;
      border-radius: 5px;
      cursor: pointer;
    }

    > svg {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.error};
      cursor: pointer;
    }

    > h3 {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-bottom: 20px;
      color: ${props => props.theme.colors.mainColor};
    }

    div {
      strong {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
      }

      ul {
        margin: 10px 25px 15px;
        font-size: 13px;

        li {
          margin-bottom: 5px;
          list-style: circle;
        }
      }
    }

    .confirmations {
      width: 95%;

      span {
        display: inline-block;
        margin-top: 15px;
        font-size: 11px;
      }

      > div {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    }

    @media screen and (max-width: 766px) {
      width: 400px;
    }
  }
`
