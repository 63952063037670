/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import { FiHelpCircle } from 'react-icons/fi'

import { Container } from './styles'

const Help: React.FC = () => {
  return (
    <>
      <a
        href="https://cibrius.com.br/fale-conosco/"
        target="_blank"
      >
        <Container>
          <FiHelpCircle />
          <div className="hide">
            <p>Fale com um especialista</p>
          </div>
        </Container>
      </a>
    </>
  )
}

export default Help
