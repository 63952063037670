import styled from 'styled-components'

interface BtnModalProps {
  isActive: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #0071ba;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > form {
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const BoxValue = styled.div`
  width: 100%;
  padding: 5px 30px;

  > p {
    font-size: 14px;
    font-weight: bold;
    color: #666360;
    text-align: center;
    margin-bottom: 5px;
  }

  > div {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    img {
      width: 85px;
      margin-right: 10%;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin-bottom: 8px;
      }

      input {
        border: none;
        background: transparent;
        font-size: 18px;
        font-weight: bold;
        color: #4ba134;
        width: 150px;
        margin-bottom: 6px;

        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
      }

      small {
        font-size: 11px;
        color: #666360;
      }
    }
  }

  > small {
    width: 450px;
    display: block;
    text-align: center;
    font-size: 11px;
    color: #8a8a8a;

    span {
      display: inline;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 766px) {
    padding: 5px;

    > small {
      width: auto;
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 20px auto;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props =>
    props.isActive
      ? 'linear-gradient(15deg, #537FE6, #4EB0FC)'
      : 'transparent'};
  border: ${props => (props.isActive ? 'none' : 'solid 1px #537FE6')};
  color: ${props => (props.isActive ? '#fff' : props.theme.colors.placeholder)};
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`
