// import styled, { css } from 'styled-components';


// import Tooltip from '../Tooltip'

// interface ContainerProps {
//   isFocused: boolean
//   isFilled: boolean
//   isErrored: boolean
//   sizeBox?: 'small' | 'large'
// }
// export const Container = styled.div<ContainerProps>`
//   background: initial;
//   border-radius: 4px;
//   padding: 16px 12px;
//   display: flex;
//   align-items: center;
//   width: 100%;

//   position:relative;

//   border: 1px solid #D2D2D2;
//   color: ${props => props.theme.colors.placeholder};

//   margin-bottom: 12px;
//   transition: .2s;

//   >div{
//     flex:1;
//   }


//   ${({ sizeBox }) => (sizeBox && sizeBox === 'large') && css`
//     height: 70px;
//     > label {
//       font-size: 14px;
//     }`}


//   ${props =>
//     props.isErrored &&
//     css`
//       border-color: ${props => props.theme.colors.errorText};
//     `}

//   ${props =>
//     props.isFocused &&
//     css`
//       border-color: ${props => props.theme.colors.mainColor};
//       color: ${props => props.theme.colors.mainColor};
//     `}


//   ${props =>
//     props.isFilled &&
//     css`
//       color: ${props => props.theme.colors.text};
//     `}

//   > label {
//     position: absolute;
//     font-weight: bold;
//     top: 16px;
//     left: 12px;
//     padding-right:12px;

//     color: ${({ theme }) => theme.colors.placeholder} ;

//     transition: .2s;
//     pointer-events: none;
//   }
//   ${props =>
//     props.isErrored && (props.sizeBox && props.sizeBox === 'large') &&
//     css`
//       > label {
//         font-size:13px;
//         padding-right:30px;
//       }
//     `}

// ${
//   props => (props.isFilled || props.isFocused) && css`
//     padding-top:24px;
//     ${props.sizeBox === 'large' && css`
//       padding-top:33px;
//     `}
//     > label {
//       transform: translateY(-12px);
//       font-size: 12px;
//       letter-spacing: 0.1em;
//     }
//   `}



// span {
//   color: ${ props => props.theme.colors.text};
//   margin-right: 4px;
//   display: inline-block;
// }

// input {
//   appearance: none;
//   font-size: 50px;
//   color: ${ props => props.theme.colors.text};
//   flex: 1;
//   border: 0;
//   background: transparent;

//     &::placeholder {
//     color: ${ props => props.theme.colors.placeholder};
//   }
// }
// svg {
//   margin-right: 12px;
// }

// `;

// export const Error = styled(Tooltip)`
// height: 20px;
// margin-left: 12px;

// svg {
//   margin: 0%;
// }

// span {
//   background: ${ props => props.theme.colors.errorText};
//   color: #fff;

//     &::before {
//     border-color: ${ props => props.theme.colors.errorText} transparent;
//   }
// }
// `

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  sizeBox?: 'small' | 'large'
}

export const Container = styled.div<ContainerProps>`

  color: ${props => props.theme.colors.placeholder};
  border: 1px solid #AEAEAE;
  border-radius: 4px;

  display: flex;
  height: 70px;
  width: 100%;
  padding: 16px 12px 8px;
  margin-bottom: 12px;

  position: relative;
  transition: .2s;

  > label {
    position: absolute;
    top: 5px;
    left: 8px;

    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.placeholder} ;
    pointer-events: none;
  }

  > div {
    margin-top: 5px;
    width: 100%;
  }

  ${props => props.isErrored && css`
    border-color: ${props => props.theme.colors.error};
  `}
`


export const Error = styled(Tooltip)`
  flex: 1;
  margin-left: 12px;
  margin-top: 20px;
  color: #fff;

  span {
    background: ${ props => props.theme.colors.error};
    color: #fff;
    text-align: center;

      &::before {
      border-color: ${ props => props.theme.colors.error} transparent;
    }
  }
`
