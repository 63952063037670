import React from 'react'

import { Container } from './styles'

import logoImage from '../../assets/logo_cibrius_familia.png'

const Logo: React.FC = () => {
  return <Container src={logoImage} alt="Logo" width="230" height="46" />
}

export default Logo
