import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  max-width: 600px;
  margin: 0 auto;
  padding: 0 5px;

  > strong {
    font-size: 18px;
    font-weight: bold;
    color: #0071BA;
    margin-bottom: 15px;
    text-align: center;
  }

  @media screen and (max-width: 766px) {
    max-width: 500px;
  }
`

export const Content = styled.main`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px;

  strong {
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    color: #0071BA;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const Participants = styled.div`
  h3 {
    font-size: 18px;
    color: ${props => props.theme.colors.text}
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #0071BA;
  }

  small {
    font-size: 12px;
    color: #666360;
  }

  .titular,
  .dependents {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 6px 5px 10px;
    margin-bottom: 10px;
  }

  .infos {
  }

  .planos {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  > div {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    margin: 5px 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEE;

    span {
      font-size: 16px;
      color: #0071BA;
      font-weight: bolder;
    }
  }

  @media screen and (max-width: 600px) {
    p {
      text-align: left;
    }

    > div {
      margin-top: 8px;
    }
  }
`

export const Confirmation = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 20px;

  strong {
    text-align: center;
    align-self: center;
    color: #0071BA;
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
    padding-bottom: 8px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;

    span{
      font-weight: bold;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > div {
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: justify;

    small {
      margin-right: 12px;
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }

    ul {
      list-style: none;
    }
  }
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 15px auto;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
interface BtnModalProps {
  isActive: boolean;
}
export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #537FE6, #4EB0FC)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #537FE6'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`
