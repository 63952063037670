import { transparentize } from 'polished';
import styled from 'styled-components'

interface BtnOptionProps {
  isActive: boolean;
}

interface BtnModalProps {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  max-width: 600px;
  margin: 0 auto;
  padding: 0 5px;

  > strong {
    font-size: 18px;
    font-weight: bold;
    color: #0071BA;
    margin-bottom: 15px;
    text-align: center;
  }

  @media screen and (max-width: 766px) {
    max-width: 500px;
  }
`

export const Content = styled.main`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px;

  strong {
    display: flex;
    text-align: center;
    align-self: center;
    justify-content: center;
    color: #0071BA;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const Participants = styled.div`
  border-bottom: 1px solid #EEE;

  h3 {
    font-size: 18px;
    color: ${props => props.theme.colors.text};
    margin: 10px 0;
  }

  span {
    font-size: 14px;
  }

  small {
    font-size: 12px;
    color: #666360;
  }

  .input {
    margin-bottom: 12px;
  }

  .titular,
  .dependents {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 6px 5px 10px;
    margin-bottom: 10px;
  }

  .info {
    strong {
      display: inline-block;
      color: #666360;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }

  .planos {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .tributation {
    margin: 25px auto 10px;
  }
`

export const Confirmation = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 20px;

  strong {
    text-align: center;
    align-self: center;
    color: #0071BA;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }

  p {
    font-size: 14px;
  }

  > div {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 25px;
    text-align: right;

    small {
      margin-right: 12px;
      font-weight: bold;
    }

    a {
      text-decoration: none;
    }
  }

  span {
    font-weight: bold;
    font-size: 14px;
    margin-right: 15px;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;
  
  width: 100%;
  padding: 16px 12px;
  margin-bottom: 12px;
  
  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#AEAEAE')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;
  
  > label {
    transform: translateY(-12px);
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
  }

  div.switch {
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;

    small {
      margin: 0 12px;
      font-size: 12px;
    }

    a {
      text-decoration: none;
    }
  }

  svg {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    color: ${props => props.theme.colors.mainColor};
    cursor: pointer;
    transform: scale(1);
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

`


export const BtnOption = styled.button<BtnOptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 4px;
  padding: 4px 12px;
  min-width: 140px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #4BA134, #31841A)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #4BA134'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  cursor: default;
`

export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #537FE6, #4EB0FC)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #537FE6'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`