/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { addMonths } from 'date-fns'
import { FiCheck, FiCheckCircle } from 'react-icons/fi'
import { Slider } from '@material-ui/core'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'

import { formatValueReduced, formatValue } from '../../utils/formatValues'
import usePersistedState from '../../hooks/usePersistedState'
import { Valor } from '../../utils/masks'

import Header from '../../components/Header'
import Button from '../../components/Button'
import {
  Container,
  Content,
  GraphWrapper,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  InfoValuesBox,
  ButtonSimulationValue,
  SimulationInfo,
} from './styles'
import { UserDetails, UserData } from '../../utils/interfaces'
import { useLeadLovers } from '../../hooks/mectrics'

interface CustomTooltipInterface {
  active: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  label: string
}

interface Values {
  year: string
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | null
  >('receiveTypeSelected', null)

  const [totalRentability, setTotalRentability] = usePersistedState(
    'totalRentability',
    0,
  )
  const [timeValueYears, setTimeValueYears] = usePersistedState<number>(
    'TimeValueYears',
    5,
  )
  const [temporaryTimeValueYears, setTemporaryTimeValueYears] = useState(
    timeValueYears || 5,
  )
  const [
    temporaryTimeValueYearsLabel,
    setTemporaryTimeValueYearsLabel,
  ] = useState([{ label: '', value: 0 }])

  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 0.3)
  const [temporaryPercent, setTemporaryPercent] = useState(
    percentualValuePercent
      ? parseFloat(percentualValuePercent.toFixed(2))
      : 0.3,
  )
  const [percentageLabelValues, setPercentageLabelValues] = useState([
    { label: '', value: 0 },
  ])

  const [displayPercent, setDisplayPercent] = useState(false)
  const [displayTime, setDisplayTime] = useState(false)

  const [values, setValues] = useState<Values[]>([])
  const [totalInvested, setTotalInvested] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [launched, setLaunched] = useState(false)

  const history = useHistory()
  const leadLovers = useLeadLovers()

  const definePercentageValueLabels = () => {
    const labelValues = []

    for (let label = 0.2; label <= 2; label += 0.2) {
      labelValues.push({
        value: parseFloat(label.toFixed(1)),
        label: `${label.toFixed(1)}%`,
      })
    }

    setPercentageLabelValues(labelValues)
  }

  const defineTimeValueYearsLabels = () => {
    const labelValues = []

    for (let label = 4; label <= 30; label += 2) {
      labelValues.push({
        value: label,
        label: `${label}`,
      })
    }

    setTemporaryTimeValueYearsLabel(labelValues)
  }

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Notes: 'Última página: Simulação',
    }

    leadLovers.put(leadData)
  }, [])

  useEffect(() => setTotalRentability((totalBalance * 100) / totalInvested), [
    totalInvested,
    totalBalance,
    setTotalRentability,
  ])

  const percentualValue = useMemo(
    () => formatValue(totalBalance * (temporaryPercent / 100)),
    [temporaryPercent, totalBalance],
  )
  const timeValue = useMemo(
    () => formatValue(totalBalance / (temporaryTimeValueYears * 12)),
    [temporaryTimeValueYears, totalBalance],
  )

  const { contribuicaoBasica, years } = userDetails
  const actualYear = new Date().getFullYear()
  const lastYear = actualYear + parseInt(years.toString(), 10)
  const rentability = 0.68214934 // 8.5%a.a

  const createValues = useCallback(() => {
    const valuesCalculated: Values[] = []
    let totalInvestedTemp = totalInvested + userDetails.aport
    let temporaryFinalBalance = totalInvestedTemp

    const contBasicaPartic = userDetails.contribuicaoBasica
    const totalMonthsInvested = userDetails.years * 12
    let finalBalance = 0
    let counter = 1

    for (counter; counter <= totalMonthsInvested; counter += 1) {
      totalInvestedTemp += contBasicaPartic

      finalBalance =
        (temporaryFinalBalance + contBasicaPartic) * (rentability / 100) +
        (contBasicaPartic + temporaryFinalBalance)

      temporaryFinalBalance = finalBalance

      const val: Values = {
        // eslint-disable-next-line no-bitwise
        year: `${String(
          addMonths(new Date(), counter).toLocaleDateString('pt-BR', {
            month: 'short',
          }),
        )} ${addMonths(new Date(), counter).getFullYear()}`,
        invested: totalInvestedTemp,
        rentability,
        finalBalance,
      }
      valuesCalculated.push(val)
    }

    setTotalInvested(totalInvestedTemp)
    setTotalBalance(temporaryFinalBalance)
    setValues(valuesCalculated)
    setLaunched(true)
  }, [totalInvested, userDetails])

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | null) => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PD') {
        setDisplayTime(true)
        setDisplayPercent(false)
      } else if (selectedReceiveNow === 'PS') {
        setDisplayTime(false)
        setDisplayPercent(true)
      } else {
        setDisplayPercent(false)
        setDisplayTime(false)
      }
    },
    [setSelectedReceive],
  )

  function handleChangePercent(p: number) {
    setPercentualValuePercent(p)
  }

  function handleChangeTemporaryPercent(p: number) {
    setTemporaryPercent(p)
  }

  function handleChangeTimeValueYears(t: number) {
    setTimeValueYears(t)
  }

  function handleChangeTemporaryTimeValueYears(t: number) {
    setTemporaryTimeValueYears(t)
  }

  useEffect(() => {
    toggleSelectedReceive(selectedReceive)
  }, [selectedReceive, toggleSelectedReceive])

  const graphValues = values.map(value => ({
    year: value.year,
    value: value.finalBalance,
    formattedValue: Valor(value.finalBalance.toFixed(2)),
  }))

  function CustomTooltip({ active, payload, label }: CustomTooltipInterface) {
    if (active) {
      return (
        <div className="tooltip">
          <h4>Ano: {label}</h4>
          <p>
            Saldo: <span>{Valor(payload[0].value.toFixed(2))}</span>
          </p>
        </div>
      )
    }
    return null
  }

  const handleConfirmValues = useCallback(() => {
    // history.push('/address-infos')
    history.push('/goal-selection')
  }, [history])

  useEffect(() => {
    definePercentageValueLabels()
    defineTimeValueYearsLabels()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div className="values-box">
            <InfoValuesBox color="green" gradientDirection="right">
              <span>Valor Investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>Seu investimento no período</small>
            </InfoValuesBox>

            <InfoValuesBox color="blue" gradientDirection="left">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Seu investimento + rentabilidade</small>
            </InfoValuesBox>
          </div>
          <article>
            <p>
              Os dados não mentem! <br />
              Quer ver? Arraste seu mouse no gráfico e descubra a evolução do
              seu patrimônio no decorrer dos próximos anos.
            </p>
          </article>

          <GraphWrapper>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={graphValues}>
                <defs>
                  <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#4BA134" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#4BA134" stopOpacity={0.05} />
                  </linearGradient>
                </defs>

                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#31841A"
                  fillOpacity={1}
                  fill="url(#color)"
                />

                <Tooltip
                  content={
                    <CustomTooltip active={false} payload={2} label="" />
                  }
                />
                <XAxis name="Ano" dataKey="year" />
                <YAxis
                  name="Saldo"
                  dataKey="value"
                  width={75}
                  axisLine={false}
                  tickLine={false}
                  tickCount={5}
                  tickFormatter={number =>
                    `${formatValueReduced(
                      parseInt(number.toString(), 10),
                      3,
                      'R$',
                    )}`
                  }
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  opacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphWrapper>
          <small>PROJEÇÃO DE RENTABILIDADE DE 8,50% AO ANO</small>
        </Content>

        <Content>
          <strong>Desejo receber o valor mensalmente via:</strong>
          <article>
            <p>
              <i>
                Esta é uma simulação. No momento da requisição do benefício você
                poderá escolher a forma de recebimento definitiva.
              </i>
            </p>
          </article>

          <ReceiveBox>
            <ButtonSelectBox
              type="button"
              onClick={() => toggleSelectedReceive('PS')}
              selected={selectedReceive === 'PS'}
            >
              <img src={simulationSelectPercentIcon} alt="Percentual" />
              <span>Percentual sobre o saldo</span>
              <FiCheckCircle />
            </ButtonSelectBox>
            <SimulationInfo displayed={displayPercent}>
              Aqui você define o percentual mensal que deseja receber da sua
              reserva acumulada. Você poderá definir este percentual, que varia
              de 0,2 a 2% do saldo que você constituiu, no momento da sua
              aposentadoria. Sua reserva será consumida mensalmente de acordo
              com o valor de benefício calculado. Você receberá caso escolha
              receber uma renda que corresponde a {temporaryPercent.toFixed(2)}%
              do seu saldo.
            </SimulationInfo>
            <BoxButtons displayed={displayPercent}>
              <ButtonSimulationValue type="button">
                <span>{parseFloat(temporaryPercent.toFixed(2))}%</span>
              </ButtonSimulationValue>
            </BoxButtons>

            <div
              className="slider-wrapper"
              style={{ display: displayPercent ? 'block' : 'none' }}
            >
              <Slider
                defaultValue={parseFloat(temporaryPercent.toFixed(2))}
                value={parseFloat(temporaryPercent.toFixed(2))}
                step={0.01}
                min={0.2}
                max={2}
                marks={percentageLabelValues}
                onChange={(e, v) => handleChangeTemporaryPercent(Number(v))}
                onChangeCommitted={(e, v) => handleChangePercent(Number(v))}
                className="blue-track"
              />
            </div>

            <ColoredBoxInfo
              size="large"
              color="blue"
              gradientDirection="right"
              displayed={displayPercent}
            >
              <div>
                <h3>{percentualValue}*</h3>
                <small>Você receberá por mês</small>
                <p>
                  {/* *Valor simulado referente a{' '}
                  {temporaryPercent.toFixed(2)}% do seu saldo projetado,
                  com pagamentos mensais efetuados com base no percentual que
                  escolher no momento da aposentadoria. <br />
                  Esgotado o saldo da conta de benefício concedido, cessam os
                  pagamentos de benefícios pelo plano. */}
                  *Valor simulado do benefício mensal que você receberá caso
                  escolha receber uma renda que corresponde a{' '}
                  {temporaryPercent.toFixed(2)}% do seu saldo acumulado.
                </p>
                <span>
                  Se a qualquer momento o Benefício de Renda Mensal resultar em
                  valor inferior a 1 (uma) Unidade Previdenciária, que equivale
                  na data do lançamento do plano a R$ 500,00, o saldo
                  remanescente da Conta de Benefício Concedido será pago em
                  parcela única.
                </span>
              </div>
              <img src={simulationPercentageInfo} alt="Percentual" />
            </ColoredBoxInfo>
          </ReceiveBox>

          <ReceiveBox>
            <ButtonSelectBox
              type="button"
              onClick={() => toggleSelectedReceive('PD')}
              selected={selectedReceive === 'PD'}
            >
              <img src={simulationSelectTimeIcon} alt="Anos" />
              <span>Prazo Determinado</span>
              <FiCheckCircle />
            </ButtonSelectBox>
            <SimulationInfo displayed={displayTime}>
              Aqui você define o prazo. Por quanto tempo quer receber seu
              pagamento mensal? Nessa modalidade, o valor do benefício será
              calculado com base no tempo que você escolher e nas contribuições
              que você acumulou ao longo do tempo - prazo mínimo de 4 anos. Não
              se preocupe, você poderá alterar esse prazo no momento da sua
              aposentadoria.
            </SimulationInfo>
            <BoxButtons displayed={displayTime}>
              <ButtonSimulationValue type="button">
                <span>{temporaryTimeValueYears}</span>
              </ButtonSimulationValue>
            </BoxButtons>

            <div
              className="slider-wrapper"
              style={{ display: displayTime ? 'block' : 'none' }}
            >
              <Slider
                defaultValue={temporaryTimeValueYears}
                value={temporaryTimeValueYears}
                step={1}
                min={4}
                max={30}
                marks={temporaryTimeValueYearsLabel}
                onChange={(e, v) =>
                  handleChangeTemporaryTimeValueYears(Number(v))
                }
                onChangeCommitted={(e, v) =>
                  handleChangeTimeValueYears(Number(v))
                }
                className="green-track"
              />
            </div>

            <ColoredBoxInfo
              size="large"
              color="green"
              gradientDirection="right"
              displayed={displayTime}
            >
              <div>
                <h3>{timeValue}*</h3>
                <small>Você receberá por mês</small>
                <p>
                  {/* *Valor simulado referente a modalidade de prazo determinado
                  com duração de {temporaryTimeValueYears} anos calculado com base no seu
                  saldo projetado. <br />
                  Esgotado o saldo da conta de benefício concedido, cessam os
                  pagamentos de benefícios pelo plano. */}
                  *Valor simulado do benefício mensal que você receberá caso
                  escolha receber uma renda a ser paga durante{' '}
                  {temporaryTimeValueYears} anos.
                </p>
                <span>
                  Se a qualquer momento o Benefício de Renda Mensal resultar em
                  valor inferior a 1 (uma) Unidade Previdenciária, que equivale
                  na data do lançamento do plano a R$ 500,00, o saldo
                  remanescente da Conta de Benefício Concedido será pago em
                  parcela única.
                </span>
              </div>
              <img src={simulationYearsInfo} alt="Anos" />
            </ColoredBoxInfo>
          </ReceiveBox>

          <small className="comment">
            Esta simulação não representa garantia de valor de benefício futuro.
            Informamos que o valor da aposentadoria será apurado no momento da
            concessão do benefício, o que pode resultar em um valor diferente
            daquele apresentado no simulador.
          </small>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="blue"
          width="large"
          disabled={selectedReceive === null}
          onClick={handleConfirmValues}
        >
          <FiCheck size={40} />
          Pronto! Quero continuar com adesão ao Plano
        </Button>

        <Button
          type="button"
          fontSize="small"
          onClick={() => history.push('/contribution')}
        >
          Quero alterar o valor e simular novamente
        </Button>
      </Container>
    </>
  )
}

export default Simulation
