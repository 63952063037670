/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FiCheck, FiX } from 'react-icons/fi'
import { RiQuestionLine } from 'react-icons/ri'
import Modal from 'react-modal'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { UserDetails, UserData } from '../../utils/interfaces'
import {
  Container,
  Content,
  RadioButton,
  BtnVoltar,
  BtnContato,
} from './styles'
import { useLeadLovers } from '../../hooks/mectrics'

const FatcaInfo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [ppe, setPPE] = useState('N')
  const [usperson, setUsperson] = useState('N')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    await setUserDetails({
      ...userDetails,
      ppe,
      usperson,
    })

    history.push('/confirm-ownership')
  }, [history, usperson, ppe, setUserDetails, userDetails])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const leadLovers = useLeadLovers()
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  useEffect(() => {
    const leadData = {
      Email: userData.email,
      Notes: 'Última página: PEP',
    }

    leadLovers.put(leadData)
  }, [])
  return (
    <>
      <Header />
      <Container>
        <Content>
          <RiQuestionLine
            onClick={() => setIsModalOpen(true)}
            className="info"
          />
          <strong>Pessoa Exposta Politicamente (PEP)?</strong>
          <span>
            Conforme definido na Instrução PREVIC Nº 34 DE 28/10/2020, PEP
            (Pessoa Exposta Politicamente) é a pessoa que desempenha ou tenha
            desempenhado, nos cinco anos anteriores, cargo, emprego ou função
            pública relevantes, assim como funções relevantes em organizações
            internacionais, bem como com seus representantes, familiares e
            outras pessoas de seu relacionamento próximo.{' '}
          </span>
          <br />
          <span>
            Marque se tiver relação próxima, como representantes ou familiar* de
            PEP.
          </span>
          <small>
            *Familiar = cônjuge, companheiro(a), enteado(a), pai, mãe, filho,
            avô/avó, neto(a).
          </small>

          <RadioButton>
            <div>
              <BtnContato
                type="button"
                isActive={ppe === 'S'}
                onClick={() => setPPE('S')}
              >
                Sim
              </BtnContato>
              <BtnContato
                type="button"
                isActive={ppe === 'N'}
                onClick={() => setPPE('N')}
              >
                Não
              </BtnContato>
            </div>
          </RadioButton>
        </Content>

        <Content>
          <span>É residente no exterior para fins fiscais?</span>

          <RadioButton>
            <div>
              <BtnContato
                type="button"
                isActive={usperson === 'S'}
                onClick={() => setUsperson('S')}
              >
                Sim
              </BtnContato>
              <BtnContato
                type="button"
                isActive={usperson === 'N'}
                onClick={() => setUsperson('N')}
              >
                Não
              </BtnContato>
            </div>
          </RadioButton>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="blue"
          onClick={handleSubmit}
        >
          <FiCheck size={45} />
          Continuar
        </Button>
        <BtnVoltar
          type="button"
          onClick={() => history.push('/participants-list')}
        >
          &lt; Anterior
        </BtnVoltar>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content modal-content-image"
        >
          <FiX onClick={handleCloseModal} />
          <div>
            <strong>Pessoa exposta politicamente (PEP)?</strong>
            <span>
              São pessoas expostas politicamente (PEP) aqueles que atualmente ou
              nos últimos 5 anos:
            </span>

            <div>
              <ul>
                <li>
                  Detentores de mandatos eletivos dos Poderes Executivo e
                  Legislativo da União;
                </li>
                <li>
                  Ocupantes de cargo no Poder Executivo da União como ministro
                  de Estado ou equiparado, de natureza especial ou equivalente;
                </li>
                <li>
                  Presidente, vice-presidente e diretor, ou equivalentes de
                  entidades da administração pública indireta;
                </li>
                <li>
                  Exercentes de função pública federal com Direção e
                  Assessoramento Superiores (DAS), nível 6, ou equivalente;
                </li>
                <li>
                  Membros do CNJ, STF, STJ e outros tribunais superiores, TRF,
                  TRT, TRE, CSJT e CJF;
                </li>
                <li>Membros do Conselho Nacional do MP;</li>
                <li>Procurador-Geral ou Vice-Procurador-Geral da República;</li>
                <li>Procurador-Geral do Trabalho e/ou da Justiça Militar;</li>
                <li>Subprocuradores-Gerais da República</li>
                <li>
                  Procuradores-Gerais de Justiça dos Estados e do Distrito
                  Federal;
                </li>
                <li>
                  Membros do TCU, Procurador-Geral e/ou Subprocuradores-Gerais
                  do MP junto ao TCU;
                </li>
                <li>
                  Presidentes e os tesoureiros nacionais ou equivalentes, de
                  partidos políticos;
                </li>
                <li>
                  Governadores e os secretários de Estados e do Distrito
                  Federal;
                </li>
                <li>Deputados estaduais e distritais;</li>
                <li>
                  Presidentes ou equivalentes de entidades da administração
                  pública indireta estadual e distrital;
                </li>
                <li>
                  Presidentes de Tribunais de Justiça, Tribunais Militares,
                  Tribunais de Contas ou equivalentes dos Estados e do Distrito
                  Federal;
                </li>
                <li>Prefeitos, os vereadores, os secretários municipais;</li>
                <li>
                  Presidentes ou equivalentes de entidades da administração
                  pública indireta municipal;
                </li>
                <li>
                  Presidentes de Tribunais de Contas ou equivalentes dos
                  municípios;
                </li>
                <li>
                  Pessoas que, no exterior, sejam chefes de estado ou de
                  governo, políticos de escalões superiores, ocupantes de cargos
                  governamentais de escalões superiores, oficiais-generais e
                  membros de escalões superiores do Poder Judiciário, executivos
                  de escalões superiores de empresas públicas ou dirigentes de
                  partidos políticos;
                </li>
                <li>
                  Dirigentes de escalões superiores de entidades de direito
                  internacional público ou privado.
                </li>
              </ul>
            </div>

            <strong>O que é Fatca?</strong>
            <span>
              A FATCA é uma lei Norte Americana que visa combater a evasão
              fiscal do EUA em relação a rendimentos e outros ganhos financeiros
              feitos fora dos EUA por cidadãos Norte Americanos e a cidadãos
              estrangeiros nos EUA, designados como US Person. O governo
              brasileiro assinou acordo para troca de informações com a receita
              Federal Americana.
            </span>

            <div>
              <ul>
                <li>
                  Cidadania Norte Americana, incluindo os detentores de dupla
                  nacionalidade e passaporte norte-americano, ainda que residam
                  fora dos Estados Unidos;
                </li>
                <li>
                  Residente Fiscal nos EUA, ou seja, possua Green-Card e que
                  possa se tornar um residente permanente legal como uma Pessoa
                  dos EUA (U.S. Person)?
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default FatcaInfo
