import { transparentize } from 'polished'
import styled from 'styled-components'

interface BtnToggleProps {
  isActive: boolean;
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  > form {
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #0071BA;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > form {
    width: 100%;
  }

  .input + .input {
    margin-top: 12px;
  }

  small.info {
    display: block;
    padding: 8px;
    font-size: 12px;
    border-radius: 5px;
    margin-top: 2px;
    background-color: ${transparentize(0.7, '#F9E900')};
    top: -12px;
    position: relative;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;
  }
`

export const BoxValue = styled.div`
  width: 100%;
  padding: 5px 30px;

  > p {
    font-size: 14px;
    font-weight: bold;
    color: #666360;
    text-align: center;
    margin-bottom: 5px;
  }

  > div {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    img {
     width: 85px;
     margin-right: 10%;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin-bottom: 8px;
      }

      input {
        border: none;
        background: transparent;
        font-size: 18px;
        font-weight: bold;
        color: #0071BA;
        width: 150px;
        margin-bottom: 6px;

        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
      }

      small {
        font-size: 11px;
        color: #666360;
      }
    }
  }

  > small {
    font-size: 11px;
    color: #8a8a8a;
    width: 450px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  @media screen and (max-width: 766px) {
    padding: 5px;

    > small {
      width: auto;
    }
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`


export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;

  width: 100%;
  padding: 16px 12px;
  margin-bottom: 12px;

  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#AEAEAE')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;

  > label {
    transform: translateY(-12px);
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

  }
`

export const BtnToggle = styled.button<BtnToggleProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #4BA134, #31841A)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #4BA134'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
  &:disabled {
  background: ${props => props.isActive ? '#AEAEAE' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #AEAEAE'};
  color: ${props => props.isActive? '#fff' : '#AEAEAE'} ;
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 20px auto;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

interface BtnModalProps {
  isActive: boolean;
}
export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #537FE6, #4EB0FC)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #537FE6'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;

  &:focus {
    text-decoration: underline;
  }
`
