import React, { useState } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import ReactModal from 'react-modal'
import GlobalStyle from './styles/global'

import Routes from './routes'

import AppProvider from './hooks'
import { ToggleThemeProvider } from './hooks/toggleTheme'
import Help from './components/Help'
import ModalBox from './components/Modal'

ReactModal.setAppElement('#root')

const App: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  document.addEventListener('mousemove', event => {
    if (event.clientY < 5) {
      setOpen(true)
    }
  })

  return (
    <>
      <ModalBox isOpen={open} onRequestClose={() => setOpen(false)}>
        <div>
          <b>Finalize sua adesão</b>
          <br />
          <br />
          Seu futuro financeiro está esperando por você!
        </div>
      </ModalBox>

      <ToggleThemeProvider>
        <Router>
          <AppProvider>
            <Routes />
          </AppProvider>
          <GlobalStyle />
          <Help />
        </Router>
      </ToggleThemeProvider>
    </>
  )
}

export default App
