/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { Form } from '@unform/web'
import { CircularProgress } from '@material-ui/core'
import {
  BiBuilding,
  BiBuildings,
  BiCake,
  BiIdCard,
  BiMapPin,
} from 'react-icons/bi'
import { FaFemale, FaRegMoneyBillAlt } from 'react-icons/fa'
import {
  FiCheck,
  FiClock,
  FiHome,
  FiMail,
  FiMap,
  FiMapPin,
  FiSmartphone,
  FiUser,
} from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { RiCommunityLine } from 'react-icons/ri'
import {
  Container,
  Content,
  BtnVoltar,
  Participants,
  Line,
  BtnOption,
  RadioButton,
  BtnModal,
} from './styles'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import ModalBox from '../../components/Modal'

import usePersistedState from '../../hooks/usePersistedState'

import {
  UserData,
  UserDetails,
  Participant,
  LegalIndicationData,
  FinancialIndicationData,
  ConfigData,
  UserAccount,
  UserAddress,
  ErroProps,
} from '../../utils/interfaces'
import calculaIdade from '../../utils/calculaIdade'
import { ValorMascarar } from '../../utils/masks'
import api from '../../services/api'

interface CpfFuncionario {
  cpf: string
}

const Resume: React.FC = () => {
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userAccount] = usePersistedState<UserAccount>(
    'userAccount',
    {} as UserAccount,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userAddress] = usePersistedState<UserAddress>(
    'userAddress',
    {} as UserAddress,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [respLegal] = usePersistedState('respLegal', false)
  const [respFinanceiro] = usePersistedState('respFinanceiro', false)
  const [legalIndicationData] = usePersistedState<LegalIndicationData>(
    'legalIndicationData',
    {} as LegalIndicationData,
  )
  const [financialIndicationData] = usePersistedState<FinancialIndicationData>(
    'financialIndicationData',
    {} as FinancialIndicationData,
  )
  const [selectedReceive] = usePersistedState<'PD' | 'PS' | null>(
    'receiveTypeSelected',
    null,
  )
  const [timeValueYears] = usePersistedState<number>('TimeValueYears', 5)
  const [percentualValuePercent] = usePersistedState<number>(
    'PercentualValuePercent',
    0.3,
  )
  const [cpfFunc] = usePersistedState<CpfFuncionario>(
    'cpfFuncionario',
    {} as CpfFuncionario,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [opcoes] = usePersistedState<string[]>('goalsSelected', [])
  const [acceptTerms] = usePersistedState('acceptTerms', false)
  const [acceptTerms2] = usePersistedState('acceptTerms2', false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [txtModal, setTxtModal] = useState('')
  const [flagRota, setFlagRota] = useState(false)
  const [rota, setRota] = useState('')

  const history = useHistory()

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    if (flagRota) {
      history.push(`${rota}`)
    }
  }, [flagRota, history, rota])

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)

    const parametros = Base64.encode(`{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name.toLowerCase()}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "cnpj": "${
          userData.cnpj
            ? userData.cnpj
                .replaceAll('.', '')
                .replace('-', '')
                .replace('/', '')
            : ''
        }",
        "rg": "${
          userData.rg ? userData.rg.replaceAll('.', '').replaceAll('-', '') : ''
        }",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "sexo": "${userData.sexo}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "nomeMae": "${userData.parental.toLowerCase()}",
        "modeloFiliacao": "${userDetails.filiacao ? userDetails.filiacao : ''}",
        "valor":"${userDetails.contribuicaoBasica
          .toFixed(2)
          .toString()
          .replaceAll('.', ',')}",
        "aporteInicial":"${userDetails.aport
          .toFixed(2)
          .toString()
          .replaceAll('.', ',')}",
        "valor_resgate": "${
          selectedReceive === 'PS'
            ? percentualValuePercent.toFixed(2)
            : timeValueYears
        }",
        "forma_resgate": "${selectedReceive}",
        "tempo_contribuicao": "${userDetails.years}",
        "dataPag":"${userDetails.paymentDay ? userDetails.paymentDay : ''}",
        "formaPagamento":"${userAccount.paymentType}",
        "banco":"${userAccount.paymentType === 'B' ? '' : userAccount.banco}",
        "agencia":"${
          userAccount.paymentType === 'B' ? '' : userAccount.agencia
        }",
        "conta":"${userAccount.paymentType === 'B' ? '' : userAccount.conta}",
        "endereco": [{
          "cep":"${userAddress.cep.replace('-', '')}",
          "logradouro":"${userAddress.logradouro.toLowerCase()}",
          "bairro":"${userAddress.bairro.toLowerCase()}",
          "municipio":"${userAddress.municipio.toLowerCase()}",
          "numero":"${userAddress.numero}",
          "complemento":"${userAddress.complemento.toLowerCase()}",
          "uf":"${userAddress.uf}"
        }],
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "cpfIndicacao": "${
          cpfFunc.cpf === undefined
            ? ''
            : cpfFunc.cpf.replace('.', '').replace('.', '').replace('-', '')
        }",
        "cpfRespFinanc": "${
          financialIndicationData.cpfResponsavelFin === undefined
            ? ''
            : financialIndicationData.cpfResponsavelFin
                .replace('.', '')
                .replace('.', '')
                .replace('-', '')
        }",
        "emailRespFinanc": "${
          financialIndicationData.emailResponsavelFin === undefined
            ? ''
            : financialIndicationData.emailResponsavelFin
        }",
        "nascRespFinanc":"${
          financialIndicationData.birthdateResponsavelFin === undefined
            ? ''
            : financialIndicationData.birthdateResponsavelFin
        }",
        "nomeRespFinanc": "${
          financialIndicationData.nomeResponsavelFin === undefined
            ? ''
            : financialIndicationData.nomeResponsavelFin.toLowerCase()
        }",
        "telRespFinanc": "${
          financialIndicationData.telResponsavelFin === undefined
            ? ''
            : `55${financialIndicationData.telResponsavelFin
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "respLegal": [{
          "cpfRespLeg": "${
            legalIndicationData.cpfResponsavelLeg === undefined
              ? ''
              : legalIndicationData.cpfResponsavelLeg
                  .replace('.', '')
                  .replace('.', '')
                  .replace('-', '')
          }",
          "emailRespLeg": "${
            legalIndicationData.emailResponsavelLeg === undefined
              ? ''
              : legalIndicationData.emailResponsavelLeg
          }",
          "nascimentoRespLeg":"${
            legalIndicationData.birthdateResponsavelLeg === undefined
              ? ''
              : legalIndicationData.birthdateResponsavelLeg
          }",
          "nomeRespLeg": "${
            legalIndicationData.nomeResponsavelLeg === undefined
              ? ''
              : legalIndicationData.nomeResponsavelLeg.toLowerCase()
          }",
          "telRespLeg": "${
            legalIndicationData.telResponsavelLeg === undefined
              ? ''
              : `55${legalIndicationData.telResponsavelLeg
                  .replace('(', '')
                  .replace(') ', '')
                  .replace('-', '')}`
          }"}
        ],
        "objetivos": [
          ${opcoes.map(opc => `{"id": "${opc}"}`)}
        ],
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name.toLowerCase()}",
          "tipo": "${participant.details.tipoBen}",
          "parentesco": "${
            participant.details.grauParentesco === '' ||
            participant.details.grauParentesco === undefined
              ? 99
              : participant.details.grauParentesco
          }",
          "nascimento": "${participant.data.birthdate}",
          "fator": "${
            participant.details.proporcao
              ? participant.details.proporcao.toString().replace('.', ',')
              : '0'
          }",
          "cpf": "${participant.data.cpf
            .replaceAll('.', '')
            .replace('-', '')}"}`,
        )}]
        }`)

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@CibriusFamilia:userDetails')
        localStorage.removeItem('@CibriusFamilia:userAddress')
        localStorage.removeItem('@CibriusFamilia:userAccount')
        localStorage.removeItem('@CibriusFamilia:acceptTerms')
        localStorage.removeItem('@CibriusFamilia:erroProps')
        localStorage.removeItem('@CibriusFamilia:TimeValueYears')
        localStorage.removeItem('@CibriusFamilia:participantsGroup')
        localStorage.removeItem('@CibriusFamilia:token')
        localStorage.removeItem('@CibriusFamilia:tipoCont')
        localStorage.removeItem('@CibriusFamilia:cpfFuncionario')
        localStorage.removeItem('@CibriusFamilia:financialIndicationData')
        localStorage.removeItem('@CibriusFamilia:legalIndicationData')
        localStorage.removeItem('@CibriusFamilia:respLegalEqualFin')
        localStorage.removeItem('@CibriusFamilia:tipoPessoa')
        localStorage.removeItem('@CibriusFamilia:respFinanceiro')
        localStorage.removeItem('@CibriusFamilia:acceptTerms2')
        localStorage.removeItem('@CibriusFamilia:respLegal')
        localStorage.removeItem('@CibriusFamilia:menorIdade')
        localStorage.removeItem('@CibriusFamilia:userData')
        localStorage.removeItem('@CibriusFamilia:theme')
        localStorage.removeItem('@CibriusFamilia:receiveTypeSelected')
        localStorage.removeItem('@CibriusFamilia:goalsSelected')
        localStorage.removeItem('@CibriusFamilia:confirmInformation')
        localStorage.removeItem('@CibriusFamilia:totalRentability')
        localStorage.removeItem('@CibriusFamilia:PercentualValuePercent')
        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          setFlagRota(true)
          setRota('/')
          // history.push('/')
        } else if (res.message === 'Request failed with status code 406') {
          setTxtModal(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          setFlagRota(true)
          setRota('/register')
          // history.push('/register')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [
    configData,
    userData,
    userDetails,
    selectedReceive,
    percentualValuePercent,
    timeValueYears,
    userAccount,
    userAddress,
    cpfFunc.cpf,
    financialIndicationData,
    legalIndicationData,
    opcoes,
    participants,
    history,
    setErroProps,
  ])

  const dependents = participants.filter(
    participant => participant.details.grauParentesco !== undefined,
  )

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Resumo</strong>

          <Form
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              cnpj: userData.cnpj,
              rg: userData.rg,
              birthdate: userData.birthdate
                ? userData.birthdate.split('-').reverse().join('/')
                : '',
              phone: userData.phone,
              email: userData.email,
              nomeMae: userData.parental,
              cep: userAddress.cep,
              numero: userAddress.numero,
              complemento: userAddress.complemento,
              contribution: ValorMascarar(
                userDetails.contribuicaoBasica.toFixed(2).toString(),
              ),
              aportInitial: ValorMascarar(
                userDetails.aport.toFixed(2).toString(),
              ),
              years: userDetails.years,
              bank: userAccount.dcrBanco,
              account: userAccount.conta,
              agency: userAccount.agencia,
              nomeResponsavelLeg: legalIndicationData.nomeResponsavelLeg,
              cpfResponsavelLeg: legalIndicationData.cpfResponsavelLeg,
              birthdateResponsavelLeg: legalIndicationData.birthdateResponsavelLeg
                ? legalIndicationData.birthdateResponsavelLeg
                    .split('-')
                    .reverse()
                    .join('/')
                : '',
              telResponsavelLeg: legalIndicationData.telResponsavelLeg,
              emailResponsavelLeg: legalIndicationData.emailResponsavelLeg,
              nomeResponsavelFin: financialIndicationData.nomeResponsavelFin,
              cpfResponsavelFin: financialIndicationData.cpfResponsavelFin,
              birthdateResponsavelFin: financialIndicationData.birthdateResponsavelFin
                ? financialIndicationData.birthdateResponsavelFin
                    .split('-')
                    .reverse()
                    .join('/')
                : '',
              telResponsavelFin: financialIndicationData.telResponsavelFin,
              emailResponsavelFin: financialIndicationData.emailResponsavelFin,
            }}
          >
            <Participants>
              <div>
                <h3>Dados cadastrais</h3>
                <Input
                  name="name"
                  sizeBox="small"
                  icon={FiUser}
                  placeholder="Nome"
                  disabled
                />
                <Input
                  name="cpf"
                  mask="cpf"
                  sizeBox="small"
                  icon={MdSecurity}
                  placeholder="CPF"
                  disabled
                />
                {userDetails.filiacao === 'PJ' ? (
                  <Input
                    placeholder="CNPJ"
                    name="cnpj"
                    icon={BiBuilding}
                    type="tel"
                    mask="cnpj"
                    disabled
                  />
                ) : (
                  <Input
                    placeholder="RG"
                    name="rg"
                    icon={BiIdCard}
                    type="tel"
                    disabled
                  />
                )}
                <Input
                  name="phone"
                  mask="phone"
                  prefix="+55 | "
                  placeholder="Celular com DDD"
                  icon={FiSmartphone}
                  sizeBox="small"
                  disabled
                />
                <Input
                  icon={FiMail}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  sizeBox="small"
                  disabled
                />
                <Input
                  icon={BiCake}
                  name="birthdate"
                  mask="date"
                  placeholder="Data de nascimento"
                  disabled
                />
                <Input
                  name="nomeMae"
                  sizeBox="small"
                  icon={FaFemale}
                  placeholder="Nome da Mãe"
                  disabled
                />
                <RadioButton>
                  <label>Sexo</label>
                  <div>
                    <BtnOption type="button" isActive={userData.sexo === 'M'}>
                      Masculino
                    </BtnOption>

                    <BtnOption type="button" isActive={userData.sexo === 'F'}>
                      Feminino
                    </BtnOption>

                    <BtnOption type="button" isActive={userData.sexo === 'O'}>
                      Outro
                    </BtnOption>
                  </div>
                </RadioButton>
                <RadioButton>
                  <label>Modelo de Filiação</label>
                  <div>
                    <BtnOption
                      type="button"
                      isActive={userDetails.filiacao === 'F'}
                    >
                      Pessoa Física - Família
                    </BtnOption>

                    <BtnOption
                      type="button"
                      isActive={userDetails.filiacao === 'V'}
                    >
                      Pessoa Física - Vinculado
                    </BtnOption>
                  </div>
                </RadioButton>
              </div>

              <Line />

              <div>
                <h3>Endereço</h3>
                <Input
                  name="cep"
                  sizeBox="small"
                  placeholder="CEP"
                  mask="cep"
                  icon={BiMapPin}
                  disabled
                />
                <Input
                  name="logradouro"
                  sizeBox="small"
                  placeholder="Logradouro"
                  icon={FiHome}
                  value={userAddress.logradouro}
                  disabled
                />
                <Input
                  name="bairro"
                  sizeBox="small"
                  id="bairro"
                  placeholder="Bairro"
                  icon={RiCommunityLine}
                  value={userAddress.bairro}
                  disabled
                />
                <Input
                  name="municipio"
                  sizeBox="small"
                  placeholder="Município"
                  icon={BiBuildings}
                  value={userAddress.municipio}
                  disabled
                />
                <Input
                  name="numero"
                  sizeBox="small"
                  placeholder="Número"
                  icon={AiOutlineFieldNumber}
                  disabled
                />
                <Input
                  name="complemento"
                  sizeBox="small"
                  placeholder="Complemento"
                  icon={FiMapPin}
                  disabled
                />
                <Input
                  name="uf"
                  sizeBox="small"
                  placeholder="UF"
                  value={userAddress.uf}
                  maxLength={2}
                  icon={FiMap}
                  disabled
                />
              </div>

              <Line />

              <div>
                <h3>Informações adicionais</h3>
                <Input
                  name="contribution"
                  prefix="R$"
                  sizeBox="small"
                  icon={FaRegMoneyBillAlt}
                  placeholder="Contribuição mensal"
                  disabled
                />
                <Input
                  name="aportInitial"
                  prefix="R$"
                  sizeBox="small"
                  icon={FaRegMoneyBillAlt}
                  placeholder="Aporte Inicial"
                  disabled
                />
                <Input
                  icon={FiClock}
                  sizeBox="small"
                  name="years"
                  type="number"
                  min={1}
                  placeholder="Por quantos anos deseja contribuir?"
                  disabled
                />
                <Input
                  sizeBox="small"
                  name="paymentType"
                  value={
                    userAccount.paymentType === 'B'
                      ? 'Boleto'
                      : 'Débito em conta'
                  }
                  placeholder="Forma de pagamento da contribuição"
                  disabled
                />

                {userAccount.paymentType === 'D' && (
                  <div>
                    <Input
                      sizeBox="small"
                      name="paymentDay"
                      placeholder="Data de pagamento"
                      value="5° dia útil do mês subsequente à sua competência"
                      disabled
                    />
                    <Input
                      sizeBox="small"
                      name="bank"
                      placeholder="Banco"
                      disabled
                    />
                    <Input
                      sizeBox="small"
                      name="agency"
                      placeholder="Agência (sem dígito)"
                      type="text"
                      disabled
                    />
                    <Input
                      sizeBox="small"
                      name="account"
                      placeholder="Conta com dígito"
                      mask="conta"
                      disabled
                    />
                  </div>
                )}
                <RadioButton>
                  <label>Forma de recebimento</label>
                  <div>
                    <BtnOption
                      type="button"
                      isActive={selectedReceive === 'PD'}
                    >
                      Prazo determinado
                    </BtnOption>
                    <BtnOption
                      type="button"
                      isActive={selectedReceive === 'PS'}
                    >
                      Percentual sobre o saldo
                    </BtnOption>
                  </div>
                </RadioButton>
                <RadioButton>
                  <label>Pessoa politicamente exposta (PPE)?</label>
                  <div>
                    <BtnOption type="button" isActive={userDetails.ppe === 'S'}>
                      Sim
                    </BtnOption>
                    <BtnOption type="button" isActive={userDetails.ppe === 'N'}>
                      Não
                    </BtnOption>
                  </div>
                </RadioButton>
                <RadioButton>
                  <label>É residente no exterior para fins fiscais?</label>
                  <div>
                    <BtnOption
                      type="button"
                      isActive={userDetails.usperson === 'S'}
                    >
                      Sim
                    </BtnOption>
                    <BtnOption
                      type="button"
                      isActive={userDetails.usperson === 'N'}
                    >
                      Não
                    </BtnOption>
                  </div>
                </RadioButton>
              </div>

              {respLegal && (
                <div>
                  <h3>Responsável Legal</h3>
                  <Input
                    placeholder="Nome do responsável legal"
                    name="nomeResponsavelLeg"
                    icon={FiUser}
                    disabled
                  />
                  <Input
                    placeholder="CPF do responsável legal"
                    name="cpfResponsavelLeg"
                    icon={MdSecurity}
                    mask="cpf"
                    maxLength={14}
                    disabled
                  />
                  <Input
                    icon={BiCake}
                    name="birthdateResponsavelLeg"
                    mask="date"
                    placeholder="Data de nascimento do responsável legal"
                    disabled
                  />
                  <Input
                    placeholder="Telefone do responsável legal"
                    name="telResponsavelLeg"
                    mask="phone"
                    icon={FiSmartphone}
                    disabled
                  />
                  <Input
                    placeholder="E-mail do responsável legal"
                    name="emailResponsavelLeg"
                    type="mail"
                    icon={FiMail}
                    disabled
                  />
                </div>
              )}

              {respFinanceiro && (
                <div>
                  <h3>Responsável Financeiro</h3>
                  <Input
                    placeholder="Nome do responsável financeiro"
                    name="nomeResponsavelFin"
                    icon={FiUser}
                    disabled
                  />
                  <Input
                    placeholder="CPF do responsável financeiro"
                    name="cpfResponsavelFin"
                    icon={MdSecurity}
                    mask="cpf"
                    maxLength={14}
                    disabled
                  />
                  <Input
                    icon={BiCake}
                    name="birthdateResponsavelFin"
                    mask="date"
                    placeholder="Data de nascimento do responsável financeiro"
                    disabled
                  />
                  <Input
                    placeholder="Telefone do responsável financeiro"
                    name="telResponsavelFin"
                    mask="phone"
                    icon={FiSmartphone}
                    disabled
                  />
                  <Input
                    placeholder="E-mail do responsável financeiro"
                    name="emailResponsavelFin"
                    type="mail"
                    icon={FiMail}
                    disabled
                  />
                </div>
              )}

              {participants.length > 0 ? (
                <div>
                  <h3>Beneficiários:</h3>
                  {dependents.map(d => (
                    <div key={Math.random()} className="dependents">
                      <div className="info">
                        <p>
                          <strong>Nome:</strong> {d.data.name}
                        </p>
                        <p>
                          <strong>Parentesco:</strong>{' '}
                          {d.details.dcrGrauParentesco}
                        </p>
                        <p>
                          <strong>Percentual:</strong> {d.details.proporcao}%
                        </p>
                        <small>
                          {calculaIdade(d.data.birthdate)}{' '}
                          {calculaIdade(d.data.birthdate) > 1 ? 'anos' : 'ano'}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}
            </Participants>
          </Form>
        </Content>

        <Button
          type="button"
          color="blue"
          width="large"
          disabled={!acceptTerms || !acceptTerms2 || !reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/conclusion')}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Resume
